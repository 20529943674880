// ==========================================================================
// Plugins
// ==========================================================================

require('./libs/_grunticon.js');
require('./libs/_match-media.js');
window.enquire = require('enquire.js');
require('ddbreakpoints');
require('velocity-animate');
require('slick-carousel');
require('jquery-validation');
window.Cookies = require('js-cookie');

require('./plugins/_browser.js');
require('./plugins/_dd.a11y.js');
require('./plugins/_dd.bpAttach.js');
require('./plugins/_dd.noScroll.js');
require('./plugins/_iframeResizer.min.js');
require('./plugins/_jquery.ba-throttle-debounce.js');
require('./plugins/_jquery.ddAutocomplete.js');
require('./plugins/_jquery.ddEqualHeights.js');
require('./plugins/_jquery.ddExpandCollapse.js');
require('./plugins/_jquery.ddModals.js');
require('./plugins/_jquery.ddOffscreen.js');
require('./plugins/_jquery.ddOnscreen.js');
require('./plugins/_jquery.ddResponsiveTable.js');
require('./plugins/_jquery.ddShade.js');
require('./plugins/_jquery.ddTabs.js');
require('./plugins/_jquery.ddTooltip.js');
require('./plugins/_jquery.dowhen.js');
require('./plugins/_jquery.hoverIntent.js');
require('./plugins/_jquery.serialize-object.js');
require('./plugins/_jquery.vide.js');
require('./plugins/_request-animation-frame.js');
