// ==========================================================================
// NO SCROLL - Stops the page from scrolling
// ==========================================================================
/**
 * Deloitte Digital global namespace for modules
 * @namespace DD
 */
;(function($, window, document, undefined) {
	'use strict';

	window.DD = window.DD || {};

	/**
	 * Allows for the ability to disable/enable scrolling of the page
	 *
	 * @namespace noScroll
	 * @memberof DD
	 * @version 1.0.0
	 * @copyright 2015 Deloitte Digital Australia - http://www.deloittedigital.com/au
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	window.DD.noScroll = (function() {
		var CLASSES,
			SELECTORS,
			$body = $('body'),
			set,
			unset,
			refresh;

		CLASSES = {
			NO_SCROLL: 'has-no-scroll'
		};

		SELECTORS = {
			GLOBAL_HEADER: '.global-header'
		}

		/**
		 * Stops the page from scrolling
		 *
		 * @memberof DD.noScroll
		 */
		set = function() {
			if ($body.hasClass(CLASSES.NO_SCROLL) === false) {
				var scrollTop = $(window).scrollTop();

				$body.addClass(CLASSES.NO_SCROLL).css({
					top: -scrollTop
				});
			}
		};

		/**
		 * Allows the page to start scrolling again
		 *
		 * @memberof DD.noScroll
		 */
		unset = function() {
			if ($body.hasClass(CLASSES.NO_SCROLL)) {
				var top = parseInt($body.css('top'), 10);

				$body.css({
					top: ''
				}).removeClass(CLASSES.NO_SCROLL);

				if (DD.bp.is('l') && !$(SELECTORS.GLOBAL_HEADER).hasClass('is-sticky')) {
					$(window).scrollTop(-top);
				}
			}
		};

		/**
		 * On change of the scroll height of the page, update the scroll position
		 *
		 * @memberof DD.noScroll
		 */
		refresh = function() {
			if ($body.hasClass(CLASSES.NO_SCROLL)) {
				// If the page has gotten shorter, make sure we aren't scrolled past the footer
				if ($body.height() > $(window).height()) {
					if ($body.offset().top - $(window).height() < -$body.height()) {
						$body.css({
							top: -($body.height() - $(window).height())
						});
					}
				}
			}
		};

		return {
			set: set,
			unset: unset,
			refresh: refresh
		};
	}());

}(jQuery, window, document));
