
/* =============================================================================
   DD OFFSCREEN - A jQuery plugin to offscreen nav
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/tabs.html */
;(function($, window, document, undefined) {

	'use strict';

	var	_pageHasOffscreenOpen = false,
		_$html,
		_$pageWrap,
		_$currentOffscreen,
		_$currentOffscreenOpener,
		_checkDependencies,
		_updateOptionsFromDOM,
		_toggleOffscreen,
		_closeOffscreen,
		_init;

	_checkDependencies = function() {
		if (typeof (DD) === 'undefined') {
			throw new Error('$.ddOffscreen: DD.bp and DD.a11y are required dependencies for this plugin.');
		}

		if (typeof (DD.a11y) === 'undefined') {
			throw new Error('$.ddOffscreen: DD.a11y is a required dependency for this plugin.');
		}

		if (typeof (DD.bp) === 'undefined') {
			throw new Error('$.ddOffscreen: DD.bp is a required dependency for this plugin.');
		}

		if (typeof (enquire) === 'undefined') {
			throw new Error('$.ddOffscreen: enquire.js is a required dependency for this plugin.');
		}
	};

	/**
	 * Look at the container to see if there are any DOM attributes that
	 * can override base options
	 *
	 * @memberof $.ddOffscreen
	 * @param {Object} $container The jQuery element to group
	 * @param {Object} options The options passed to the plugin
	 * @private
	 * */
	_updateOptionsFromDOM = function($container, options) {
		var updatedOptions = $.extend(true, {}, options);

		updatedOptions.at = $container.attr(options.attrs.at) || options.at;

		if ($container.hasClass(options.classes.isRight)) {
			updatedOptions.isRight = true;
		}

		if (updatedOptions.isRight && !$container.hasClass(options.classes.isRight)) {
			$container.addClass(options.classes.isRight);
		}

		return updatedOptions;
	};

	_toggleOffscreen = function(event, options) {
		event.preventDefault();

		var $button = $(event.currentTarget),
			id = $button.attr(options.attrs.id) || $button.attr('href').replace(/[\w\W]*#([\w\W]+)/g, '$1'),
			$container = $(document.getElementById(id));

		if ($container.length === 0) {
			console.warn('$.ddOffscreen: Can\'t find an offscreen panel with id "' + id + '".');
			return;
		}

		_$currentOffscreenOpener = $button;

		if (!_pageHasOffscreenOpen) {
			$container.trigger('open.ddOffscreen');
			$button.addClass(options.classes.isActive);
			return;
		}

		$container.trigger('close.ddOffscreen');
	};

	_closeOffscreen = function(event) {
		event.preventDefault();

		if (_pageHasOffscreenOpen && _$currentOffscreen) {
			_$currentOffscreen.trigger('close.ddOffscreen');
		}
	};

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddOffscreen
	 * @param {Object} $container The jQuery element to group
	 * @param {Object} options The options passed to the plugin
	 * @param {Number} i Index of the item on the page to help generate unique ids
	 * @private
	 * */
	_init = function(container, options) {
		var $container = $(container),
			$content = $container.find('.' + options.classes.content),
			$toggleButton = $('.' + options.triggers.toggle).filter('[' + options.attrs.id + '="' + $container.attr('id') + '"], [href="#' + $container.attr('id') + '"]'),
			_isDisabled = false,
			_isAnimating = false,
			_isInit = false,
			_isOpen = false,
			_animations,
			_open,
			_close,
			_enable,
			_disable,
			_initOffscreen,
			_initOffscreenAt;

		// don't run more than once
		if (typeof ($container.data('ddOffscreen-isInit')) === 'boolean' && $container.data('ddOffscreen-isInit') === true) {
			return;
		}

		options = _updateOptionsFromDOM($container, options);

		for (var key in options.animations) {
			if (options.animations.hasOwnProperty(key)) {
				if (key === options.animationType) {
					_animations = options.animations[key];
				}
			}
		}

		if (typeof (_animations) === 'undefined') {
			console.warn('$.ddOffscreen: Animation type "' + options.animationType + '" not found. Defaulting to "over" instead.');
			_animations = options.animations.over;
		}

		_open = function() {
			if (_isDisabled) {
				return;
			}

			if (!_isOpen && !_isAnimating) {
				_isAnimating = true;

				var onOpenComplete = function() {
					_$html.addClass(options.classes.isOpen);
					_$html.removeClass(options.classes.isAnimating).removeClass(options.classes.isAnimatingOpen);

					var $offscreenContainer;
					if (options.offscreenContainer) {
						$offscreenContainer = options.offscreenContainer;
					} else {
						$offscreenContainer = $container;
					}

					DD.a11y.tabInsideContainer.set($offscreenContainer, true);
					DD.a11y.onEscape.set(_close);
					DD.a11y.onClickOutside.set($offscreenContainer, _close);
					DD.a11y.ariaHideOthers.set($offscreenContainer);

					_animations.reset($container, _$pageWrap, $content);

					_pageHasOffscreenOpen = true;
					_isOpen = true;
					_isAnimating = false;

					_$currentOffscreen = $container;

					$container.attr({
						'aria-hidden': false
					}).focus();
					$toggleButton.attr({
						'aria-expanded': true
					});
					$('.login-trigger').focus();
				};

				DD.noScroll.set();

				$container.addClass(options.classes.isActive);
				_$html.addClass(options.classes.isAnimating).addClass(options.classes.isAnimatingOpen);
				_animations.show($container, _$pageWrap, $content, options, onOpenComplete);

				if (options.hasBackground) {
					$.ddShade.setActive(true);
					$.ddShade.opacity(options.backgroundOpacity, options.durations.show);
				}
			}
		};

		_close = function() {
			if (_isOpen && !_isAnimating) {
				_isAnimating = true;

				var onCloseComplete = function() {
					_$html.removeClass(options.classes.isOpen);
					_$html.removeClass(options.classes.isAnimating).removeClass(options.classes.isAnimatingClose);
					$container.removeClass(options.classes.isActive);

					DD.a11y.tabInsideContainer.unset();
					DD.a11y.onEscape.unset();
					DD.a11y.onClickOutside.unset();
					DD.a11y.ariaHideOthers.unset();

					if (_$currentOffscreenOpener && _$currentOffscreenOpener.length > 0) {
						_$currentOffscreenOpener.removeClass(options.classes.isActive).get(0).focus();
					}

					$container.attr({
						'aria-hidden': true
					});
					$toggleButton.attr({
						'aria-expanded': false
					});

					_$currentOffscreen = null;
					_$currentOffscreenOpener = null;

					_animations.reset($container, _$pageWrap, $content);

					_pageHasOffscreenOpen = false;
					_isOpen = false;
					_isAnimating = false;
				};

				DD.noScroll.unset();

				_$html.addClass(options.classes.isAnimating).addClass(options.classes.isAnimatingClose);
				_animations.hide($container, _$pageWrap, $content, options, onCloseComplete);

				if (options.hasBackground) {
					$.ddShade.opacity(0, options.durations.hide, false, function() {
						$.ddShade.setActive(false);
					});
				}
			}
		};

		_enable = function() {
			_isDisabled = false;

			if (!_isInit) {
				_initOffscreen();
			}
		};

		_disable = function() {
			_isDisabled = true;

			if (_isOpen) {
				_close();
			}
		};

		_initOffscreen = function() {
			$container.on('open.ddOffscreen', _open);
			$container.on('close.ddOffscreen', _close);
			$container.on('enable.ddOffscreen', _enable);
			$container.on('disable.ddOffscreen', _disable);

			_isInit = true;
			_isDisabled = false;
		};

		$container.attr({
			'aria-hidden': true
		});
		$toggleButton.attr({
			'aria-expanded': false
		});

		if (options.at === true) {
			_initOffscreen();
			return;
		}

		_initOffscreenAt = function() {
			if (!_isInit) {
				_initOffscreen();
			}

			if (DD.bp.is(options.at)) {
				_enable();
			} else {
				_disable();
			}
		};

		enquire.register(DD.bp.get(options.at), {
			match: _initOffscreenAt,
			unmatch: _initOffscreenAt
		});

		$container.data('ddOffscreen-isInit', true);
	};

	$.extend({
		ddOffscreen: {
			defaults: {
				at: true,
				hasBackground: true,
				backgroundOpacity: 0.75,
				useTranslate: true,
				animationType: 'over', // can be 'side', 'over', 'full' or 'slideUp'
				isRight: false,
				navWidth: '270px',
				navHeight: '100vh',
				attrs: {
					id: 'data-offscreen-id',
					at: 'data-offscreen-at'
				},
				durations: {
					show: 400,
					hide: 400
				},
				triggers: {
					toggle: 'js-offscreen-toggle',
					close: 'js-offscreen-close'
				},
				classes: {
					pageWrap: 'page-wrap',
					content: 'offscreen-content',
					isOpen: 'offscreen-is-open',
					useTranslate: 'offscreen-use-translate',
					isActive: 'is-active',
					isRight: 'is-right',
					isAnimating: 'is-animating-offscreen',
					isAnimatingOpen: 'is-animating-open-offscreen',
					isAnimatingClose: 'is-animating-close-offscreen'
				},
				animations: {
					side: {
						show: function($container, $pageWrap, $content, options, callback) {
							var containerOptions = {},
								pageWrapOptions = {},
								contentOptions = {};

							if (options.isRight) {
								$pageWrap.addClass(options.classes.isRight);

								containerOptions.right = 0;
								pageWrapOptions.left = '-' + options.navWidth;

								$.Velocity.hook($content, 'translateX', '-50%');
							} else {
								containerOptions.left = 0;
								pageWrapOptions.left = options.navWidth;

								$.Velocity.hook($content, 'translateX', '50%');
							}

							if (options.useTranslate) {
								contentOptions.translateZ = 0;
								contentOptions.translateX = 0;
							} else {
								contentOptions.left = 0;
							}

							$container.velocity('stop').velocity(containerOptions, {
								duration: options.durations.show,
								complete: callback,
								easing: 'ease-in-out'
							});

							$pageWrap.velocity('stop').velocity(pageWrapOptions, {
								duration: options.durations.show,
								easing: 'ease-in-out'
							});

							$content.velocity('stop').velocity(contentOptions, {
								duration: options.durations.show,
								easing: 'ease-in-out'
							});
						},
						hide: function($container, $pageWrap, $content, options, callback) {
							var containerOptions = {},
								pageWrapOptions = {},
								contentOptions = {};

							if (options.isRight) {
								containerOptions.right = '-' + options.navWidth;
							} else {
								containerOptions.left = '-' + options.navWidth;
							}

							pageWrapOptions.left = 0;

							if (options.useTranslate) {
								contentOptions.translateZ = 0;
								contentOptions.translateX = '50%';

								if (options.isRight) {
									contentOptions.translateX = '-50%';
								}
							}

							$container.velocity('stop').velocity(containerOptions, {
								duration: options.durations.hide,
								complete: callback,
								easing: 'ease-in-out'
							});

							$pageWrap.velocity('stop').velocity(pageWrapOptions, {
								duration: options.durations.hide,
								easing: 'ease-in-out'
							});

							$content.velocity('stop').velocity(contentOptions, {
								duration: options.durations.hide,
								easing: 'ease-in-out'
							});
						},
						reset: function($container, $pageWrap, $content) {
							$container.velocity('stop').removeAttr('style');
							$pageWrap.velocity('stop').removeAttr('style');
							$content.velocity('stop').removeAttr('style');
						}
					},
					over: {
						show: function($container, $pageWrap, $content, options, callback) {
							var containerOptions = {};

							if (options.useTranslate) {
								if (options.isRight) {
									$.Velocity.hook($container, 'translateX', options.navWidth);
								} else {
									$.Velocity.hook($container, 'translateX', '-' + options.navWidth);
								}

								containerOptions.translateZ = 0;
								containerOptions.translateX = 0;
							} else {
								if (options.isRight) {
									containerOptions.right = 0;
								} else {
									containerOptions.left = 0;
								}
							}

							$container.velocity(containerOptions, {
								duration: options.durations.show,
								complete: callback,
								easing: 'ease-in-out'
							});
						},
						hide: function($container, $pageWrap, $content, options, callback) {
							var containerOptions = {};

							if (options.useTranslate) {
								if (options.isRight) {
									containerOptions.translateX = options.navWidth;
								} else {
									containerOptions.translateX = '-' + options.navWidth;
								}
							} else {
								if (options.isRight) {
									containerOptions.right = '-' + options.navWidth;
								} else {
									containerOptions.left = '-' + options.navWidth;
								}
							}

							$container.velocity(containerOptions, {
								duration: options.durations.hide,
								complete: callback,
								easing: 'ease-in-out'
							});
						},
						reset: function($container) {
							$container.velocity('stop').removeAttr('style');
						}
					},
					full: {
						show: function($container, $pageWrap, $content, options, callback) {
							$container.css({
								opacity: 0,
								display: 'block',
								left: 0
							});

							$.Velocity.hook($content, 'scale', 0.9);

							setTimeout(function() {
								$container.velocity({
									opacity: 1
								}, {
									duration: options.durations.show,
									complete: callback,
									easing: 'ease-out'
								});

								$content.velocity({
									scale: 1
								}, {
									duration: options.durations.show,
									easing: 'ease-out'
								});
							}, 50);
						},
						hide: function($container, $pageWrap, $content, options, callback) {
							$container.velocity({
								opacity: 0
							}, {
								duration: options.durations.hide,
								complete: callback,
								easing: 'ease-out'
							});

							$content.velocity({
								scale: 0.9
							}, {
								duration: options.durations.hide,
								easing: 'ease-out'
							});
						},
						reset: function($container, $pageWrap, $content) {
							$container.velocity('stop').removeAttr('style');
							$content.velocity('stop').removeAttr('style');
						}
					},
					slideUp: {
						show: function($container, $pageWrap, $content, options, callback) {
							var containerOptions = {};

							if (options.useTranslate) {
								$.Velocity.hook($container, 'translateY', options.navHeight);

								containerOptions.translateZ = 0;
								containerOptions.translateY = 0;
							} else {
								containerOptions.top = 0;
							}

							$container.velocity(containerOptions, {
								duration: options.durations.show,
								complete: callback,
								easing: 'ease-in-out'
							});
						},
						hide: function($container, $pageWrap, $content, options, callback) {
							var containerOptions = {};

							if (options.useTranslate) {
								containerOptions.translateY = options.navHeight;
							} else {
								containerOptions.top = options.navWidth;
							}

							$container.velocity(containerOptions, {
								duration: options.durations.hide,
								complete: callback,
								easing: 'ease-in-out'
							});
						},
						reset: function($container) {
							$container.velocity('stop').removeAttr('style');
						}
					}
				}
			}
		}
	}).fn.extend({
		ddOffscreen: function(options) {
			if (window.DD && !window.DD.a11y) {
				console.error('$.ddOffscreen: Please ensure that dd.a11y.js is included in your project.');
				return;
			}

			if (window.DD && !window.DD.noScroll) {
				console.error('$.ddOffscreen: Please ensure that dd.noScroll.js is included in your project.');
				return;
			}

			_checkDependencies();

			options = $.extend(true, {}, $.ddOffscreen.defaults, options);

			_$html = $('html');
			_$pageWrap = $('.' + options.classes.pageWrap);

			if (options.useTranslate) {
				_$html.addClass(options.classes.useTranslate);
			}

			// bind a live listener for offscreen toggle buttons
			$(document).on('click.ddOffscreen', '.' + options.triggers.toggle, function(event) {
				_toggleOffscreen(event, options);
			});

			$(document).on('click.ddOffscreen', '.' + options.triggers.close, _closeOffscreen);

			return $(this).each(function(i, el) {
				_init(el, options);
			});
		}
	});
})(jQuery, window, document);
