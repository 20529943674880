;(function($, window, document) {
	$.fn.serializeObject = function() {

		var form = this,
			o = {},
			a;

		// Remove non-numeric characters from numeric fields
		$(form).find('input[pattern="[0-9]*"]').each(function() {
			var $field = $(this);
			$field.val($field.val().replace(/\D/g, ''));
		});

		a = form.serializeArray();

		$.each(a, function() {
			if (o[this.name] !== undefined) {
				if (!o[this.name].push) {
					o[this.name] = [o[this.name]];
				}
				o[this.name].push(this.value || '');
			} else {
				o[this.name] = this.value || '';
			}
		});

		return o;
	};
}(jQuery, window, document));
