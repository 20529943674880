/**
 * Deloitte Digital global namespace for modules
 * @namespace DD
 */
;(function() {
	'use strict';

	window.DD = window.DD || {};

	/**
	 * Breakpoints for JavaScript. Works with the Deloitte Digital SCSS @bp mixin
	 *
	 * @namespace bpAttach
	 * @memberof DD
	 * @version 1.0.0
	 * @copyright 2015 Deloitte Digital Australia - http://www.deloittedigital.com/au
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	window.DD.bpAttach = (function() {
		var at;

		/**
		 * Run specific functionality at a certain breakpoint and
		 * use enquire.js to trigger the attach or detach functions
		 * as expected when the breakpoint is matched/unmatched
		 *
		 * @memberof DD.bpAttach
		 * @example
		 * // returns true if the page is between 0 and 300px high
		 * DD.bpAttach.at('m,l', doWhenAttached, doWhenNotAttached);
		 * DD.bpAttach.at('0,l', function(init) {
		 *     console.log('fire when the screen is between 0 and l');
		 *     console.log('if init === true, you can change the required code to stop inital screen flicker', init);
		 * }, function(init) {
		 *     console.log('fire when the screen is NOT between 0 and l');
		 *     console.log('if init === true, you can change the required code to stop inital screen flicker', init);
		 * });
		 *
		 * @param  {String} bp Breakpoint name as a string
		 * @param  {Function} attach Function to call on match of the included breakpoint
		 * @param  {Function} detach Function to call on unmatch of the included breakpoint
		 */
		at = function(bp, attach, detach) {
			if (!enquire) {
				console.error('DD.bpAttach: enquire.js is required to use DD.bpAttach.at()');
				return;
			}

			if (!DD.bp) {
				console.error('DD.bpAttach: DD.bp is required to use DD.bpAttach.at()');
				return;
			}

			var onAttach,
				onDetach;

			onAttach = function(init) {
				init = (typeof (init) === 'boolean' && init);

				if (typeof (attach) === 'function') {
					attach(init);
				}
			};

			onDetach = function(init) {
				init = (typeof (init) === 'boolean' && init);

				if (typeof (detach) === 'function') {
					detach(init);
				}
			};

			if (DD.bp.is(bp)) {
				onAttach(true);
			} else {
				onDetach(true);
			}

			enquire.register(DD.bp.get(bp), {
				match: onAttach,
				unmatch: onDetach
			});
		};

		return {
			at: at
		};
	}());

}());
