/* =============================================================================
   DD ONSCREEN - A jQuery plugin for onscreen nav
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/onscreen.html */
;(function($, window, document, undefined) {

	'use strict';

	var _CONST,
		_checkDependencies,
		_updateOptionsFromDOM,
		_overrideWithDataAttribute,
		_resetTimeout,
		_navHoverTimeout,
		_navBlurTimeout,
		_navIdCount,
		_init;

	_navIdCount = 0;

	_CONST = {
		KEYCODE: {
			ENTER: 13,
			SPACE: 32
		}
	};

	/**
	 * Throws an error if a dependency is missing.
	 *
	 * @memberof $.ddOnscreen
	 * @private
	 * */
	_checkDependencies = function() {
		if (typeof (DD) === 'undefined') {
			throw new Error('$.ddOnscreen: DD.bp and DD.a11y are required dependencies for this plugin.');
		}

		if (typeof (DD.a11y) === 'undefined') {
			throw new Error('$.ddOnscreen: DD.a11y is a required dependency for this plugin.');
		}

		if (typeof ($.ddShade) === 'undefined') {
			throw new Error('$.ddOnscreen: $.ddShade is a required dependency for this plugin.');
		}

		if (typeof (DD.bpAttach) === 'undefined') {
			throw new Error('$.ddOnscreen: DD.bpAttach is a required dependency for this plugin.');
		}
	};

	/**
	 * Returns the override value if the data attribute exists on the element,
	 * otherwise returns the fallback value provided.
	 *
	 * @memberof $.ddOnscreen
	 * @param {Object} $el The jQuery element to check for the data attribute on
	 * @param {String} attrName The name of the data attribute to check for
	 * @param {Object} fallbackValue The value to return if the attribute isn't set (i.e. the value being potentially overriden)
	 * @private
	 * */
	_overrideWithDataAttribute = function($el, attrName, fallbackValue) {
		var strippedAttrName,
			value;

		// strip 'data-' from beginning of attribute name if it's there
		strippedAttrName = attrName.replace(/^data-/, '');
		value = $el.data(strippedAttrName);

		// Use the original value if there is no data attribute
		if (typeof value === 'undefined') {
			return fallbackValue;
		}

		// return the override value
		return value;
	};

	/**
	 * Override option values with values from the DOM
	 *
	 * @memberof $.ddOnscreen
	 * @param {Object} $container The jQuery element to check for the data attributes on
	 * @param {Object} options The options to be overridden
	 * @private
	 * */
	_updateOptionsFromDOM = function($container, options) {
		var updatedOptions = $.extend(true, {}, options);

		updatedOptions.at = _overrideWithDataAttribute($container, options.attrs.at, options.at);

		return updatedOptions;
	};

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddOnscreen
	 * @param {Object} $container The jQuery element to group
	 * @param {Object} options The options passed to the plugin
	 * @param {Number} i Index of the item on the page to help generate unique ids
	 * @private
	 * */
	_init = function(container, options) {
		var $container = $(container),
			$nav = $container.find('.nav'),
			$items = $nav.children('.' + options.classes.navItem),
			_navid = $container.attr('id') || (options.navIdPrefix + _navIdCount),
			_currentlyOpenId,
			_openDropdown,
			_closeDropdown,
			_closeCurrentlyOpen,
			_openOnKeyPress,
			_handleCloseButtons,
			_focusBackToParentLink,
			_onEscapeDropdown,
			_animations,
			_isEnabled = true,
			_onAttach,
			_onDetach,
			_initNavItem;

		// don't run more than once
		if ($container.data('ddOnscreen-isInit') === true) {
			return;
		}

		// ensure the nav item ids are unique
		_navIdCount = _navIdCount + 1;

		// override options from dom
		options = _updateOptionsFromDOM($container, options);

		// for convenience, populate _animations with the chosen animations
		for (var key in options.animations) {
			if (options.animations.hasOwnProperty(key)) {
				if (key === options.animationType) {
					_animations = options.animations[key];
				}
			}
		}

		if (typeof (_animations) === 'undefined') {
			throw new Error('$.ddOnscreen: There is no animation defined for: \'' + options.animationType + '\'');
		}

		if (!_animations.reset || typeof (_animations.reset) !== 'function') {
			console.warn('$.ddOnscreen: If you\'re using a custom animation, you should implement the reset method too');
		}

		// Handle buttons inside a dropdown which should close it.
		_handleCloseButtons = function(id, $buttons) {
			$buttons
				.off('click.dropdown-close')
				.on('click.dropdown-close', function(event) {
					event.preventDefault();
					_closeDropdown(id);
				});

			// Give keyboard focus back to the parent link if user is navigating
			// using the keyboard
			$buttons
				.off('keydown.dropdown-close')
				.on('keydown.dropdown-close', function(event) {
					var key = event.which || event.keyCode || 0;

					if (key === _CONST.KEYCODE.ENTER) {
						_closeDropdown(id, false, true, function() {
							_focusBackToParentLink(id);
						});
					}
				});
		};

		_openDropdown = function(id, instantly, currentItemHasDropdown, callback) {
			var $link = $(document.getElementById(id)),
				isOpening = $link.hasClass(options.classes.isOpening),
				$dropdown = $link.find('.' + options.classes.dropdown),
				$closeButtons = $dropdown.find('.' + options.triggers.close),
				onOpenComplete;

			// Only start opening if the nav is enabled and there is definitely a
			// dropdown and it's not already opening.
			if (!_isEnabled || !$dropdown.length || isOpening) {
				return;
			}

			$link.removeClass(options.classes.isClosing);
			$link.addClass(options.classes.isOpening);
			$link.addClass(options.classes.hover);

			// by default the item selected currently doesn't have a dropdown
			currentItemHasDropdown = currentItemHasDropdown || false;

			_handleCloseButtons(id, $closeButtons);

			onOpenComplete = function() {
				$link.removeClass(options.classes.isOpening);

				if (_animations.reset && typeof (_animations.reset) === 'function') {
					_animations.reset($container, $link, $dropdown, options);
				}

				$container.trigger('opened.ddOnscreen', id);
				_currentlyOpenId = id;

				if (typeof (callback) === 'function') {
					callback();
				}
			};

			// display the background only if there is a menu item with a dropdown selected
			if (!options.disableBackground && options.hasBackground && $dropdown.length > 0) {
				$.ddShade.setActive(true);
				$.ddShade.setBehindHeader(true);
				$.ddShade.opacity(options.backgroundOpacity, options.durations.show, true);
			}

			DD.a11y.onEscape.set(function() {
				_onEscapeDropdown(id);
			});
			DD.a11y.onClickOutside.set($container, function() {
				_closeDropdown(id, false);
			});

			// set a11y attributes
			$link.children('a').attr({
				'aria-expanded': true
			});

			// Remove inline display: none, which is added if quickKeyboard is on
			if (options.quickKeyboard) {
				$dropdown.css({
					visibility: ''
				});
			}

			_animations.show($container, $link, $dropdown, options, (instantly && currentItemHasDropdown), onOpenComplete);
		};

		_closeDropdown = function(id, instantly, newItemHasDropdown, callback) {
			var $link = $('#' + id),
				isClosing = $link.hasClass(options.classes.isClosing),
				$dropdown = $link.find('.' + options.classes.dropdown),
				onCloseComplete;

			// Only start closing if the nav is enabled and there is definitely a
			// dropdown and it's not already closing.
			if (!_isEnabled || !$dropdown.length || isClosing) {
				return;
			}

			$link.removeClass(options.classes.isOpening);
			$link.addClass(options.classes.isClosing);

			onCloseComplete = function() {
				var isOpening = $link.hasClass(options.classes.isOpening);

				$link.removeClass(options.classes.isClosing);

				// If the dropdown has started reopening, don't interfere
				if (!isOpening) {
					if (_animations.reset && typeof (_animations.reset) === 'function') {
						_animations.reset($container, $link, $dropdown, options);
					}

					$link.removeClass(options.classes.hover);
					_currentlyOpenId = null;

					if (options.quickKeyboard) {
						$dropdown.css({
							visibility: 'hidden'
						});
					}
				}

				$container.trigger('closed.ddOnscreen', id);

				if (typeof (callback) === 'function') {
					callback();
				}
			};

			// by default the new item doesn't have a dropdown
			newItemHasDropdown = newItemHasDropdown || false;

			DD.a11y.onEscape.unset();
			DD.a11y.onClickOutside.unset();

			// set a11y attributes
			$link.find('> a').attr({
				'aria-expanded': false
			});

			// handles the dropdown navigation background fadeout
			if (options.hasBackground && (!instantly || !newItemHasDropdown)) {
				$.ddShade.opacity(0, options.durations.hide, true, function() {
					$.ddShade.setActive(false);
					$.ddShade.setBehindHeader(false);
				});
			}

			_animations.hide($container, $link, $dropdown, options, instantly, onCloseComplete);
		};

		// close the dropdown that's currently open
		_closeCurrentlyOpen = function(callback, instantly, newItemHasDropdown) {
			newItemHasDropdown = newItemHasDropdown || false;

			$nav.find('> .' + options.classes.hover).each(function() {
				_closeDropdown($(this).attr('id'), instantly, newItemHasDropdown);
			});

			if (typeof (callback) === 'function') {
				callback();
			}
		};

		// Put focus back on the parent nav item which matches the id provided
		_focusBackToParentLink = function(id) {
			$('#' + id).find('> a').eq(0).focus();
		};

		// callback for pressing escape inside a dropdown
		_onEscapeDropdown = function(id) {
			_closeCurrentlyOpen(function() {
				_focusBackToParentLink(id);
			}, true);
		};

		// Helper function for resetting a timeout
		_resetTimeout = function(timeout) {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
		};

		// Handle keyboard
		_openOnKeyPress = function(event, $item, id) {
			var $dropdown = $item.find('.' + options.classes.dropdown);

			// only allow if the navigation is enabled
			if (!_isEnabled) {
				return;
			}

			event.preventDefault();

			// check if the previous and next items have a dropdown (used for the background blockout transition)
			var fromAnotherNavItem = ($nav.find('> .' + options.classes.hover).length > 0),
				currentItemHasDropdown = ($nav.find('> .' + options.classes.hover).find('.' + options.classes.dropdown).length > 0),
				newItemHasDropdown = ($item.find('.' + options.classes.dropdown).length > 0);

			// close the currently open item
			_closeCurrentlyOpen(function() {
				_openDropdown(id, fromAnotherNavItem, currentItemHasDropdown, function() {
					// when opening a dropdown with the keyboard focus:
					var $takeFocus = $dropdown.find('.' + options.classes.takeFocus);

					if ($takeFocus.length > 0) {
						// focus on the first item with the takeFocus class
						$takeFocus.get(0).focus();
					} else {
						// otherwise focus on the first tabbable element
						$dropdown.find(':tabbable').get(0).focus();
					}
				});
			}, fromAnotherNavItem, newItemHasDropdown);
		};

		// Init nav item
		_initNavItem = function(_navid, itemid, $item) {
			var id = _navid + '-item-' + itemid,
				$itemTitle = $item.find('.' + options.classes.navItemTitle),
				$dropdown = $item.find('.' + options.classes.dropdown),
				$tabbable = $dropdown.find(':tabbable');

			// Fallback to finding the first a tag if there was nothing with the
			// navItemTitle class
			if (!$itemTitle.length) {
				$itemTitle = $item.find('> a');
			}

			// assign a unique ID to the navigation
			$item.attr('id', id);

			if ($dropdown.length) {
				$itemTitle.attr({
					'aria-expanded': false
				});
			}

			if (options.quickKeyboard) {
				$dropdown.css({
					visibility: 'hidden'
				});
			}

			// Show hover state on menu item instantly on hover
			$item.on('mouseenter.navdropdown', function(e) {
				var isNavTitle = $(e.target).hasClass(options.classes.navItemTitle),
					_onHover,
					fromAnotherNavItem = ($nav.find(e.relatedTarget).length > 0);

				// add hovering classes immediately
				$item.addClass(options.classes.hovering);

				if (!_isEnabled) {
					return;
				}

				_onHover = function() {
					// check if the previous and next items have a dropdown
					// (used for the background blockout transition)
					var currentItemHasDropdown = ($nav.find('> .' + options.classes.hover).find('.' + options.classes.dropdown).length > 0),
						newItemHasDropdown = ($item.find('.' + options.classes.dropdown).length > 0);

					if (fromAnotherNavItem) {
						_closeCurrentlyOpen(function() {
							_openDropdown(id, true, currentItemHasDropdown);
						}, true, newItemHasDropdown);
					} else {
						_openDropdown(id, false, currentItemHasDropdown);
					}
				};

				if ($item.hasClass(options.classes.hover)) {
					if (!isNavTitle || (isNavTitle && !$item.hasClass(options.classes.isClosing))) {
						_resetTimeout(_navBlurTimeout);
						return;
					}
				}

				_navHoverTimeout = setTimeout(_onHover, options.durations.hoverTimeout);
			});

			// Cancel hover timeout on mouseleave of main nav links.
			$item.on('mouseleave.navdropdown', '.' + options.classes.navItemTitle, function() {
				_resetTimeout(_navHoverTimeout);
			});

			// Remove hover state on menu item when rolled off
			$item.on('mouseleave.navdropdown', function(e) {
				$item.removeClass(options.classes.hovering);

				// check if the user has moved from one to another navigation item
				var toAnotherNavItem = ($nav.find(e.relatedTarget).length > 0);
				if (!toAnotherNavItem) {
					_navBlurTimeout = setTimeout(function() {
						_closeCurrentlyOpen(null, false);
					}, options.durations.blurTimeout);
				}
			});

			if ($dropdown.length) {
				// Handle enter and space bar
				$itemTitle.on('keydown.navdropdown', function(event) {
					if (event.which === _CONST.KEYCODE.ENTER || event.which === _CONST.KEYCODE.SPACE) {
						_openOnKeyPress(event, $item, id);
					}
				});

				// The first click should open the dropdown, the second click should follow the link
				$itemTitle.on('click.navdropdown', function(event) {
					if (_currentlyOpenId === id) {
						return true;
					} else {
						event.preventDefault();
					}
				});

				$tabbable.on('focus.navdropdown', function() {
					if (!_isEnabled) {
						return;
					}

					_resetTimeout(_navBlurTimeout);

					if ($item.hasClass(options.classes.hover)) {
						return;
					}

					var currentItemHasDropdown = ($nav.find('> .' + options.classes.hover).find('.' + options.classes.dropdown).length > 0),
						newItemHasDropdown = ($item.find('.' + options.classes.dropdown).length > 0);

					_closeCurrentlyOpen(function() {
						_openDropdown(id, true, currentItemHasDropdown);
					}, true, newItemHasDropdown);
				});

				$tabbable.on('blur.navdropdown', function() {
					_resetTimeout(_navBlurTimeout);

					_navBlurTimeout = setTimeout(function() {
						_closeDropdown(id, false);
					}, options.durations.hoverTimeout);
				});

				// Clear the blur timeout on click to prevent the dropdown from closing if a link inside the dropdown has focus and then loses focus because the user clicks inside the dropdown
				$item.on('click.navdropdown', function() {
					_resetTimeout(_navBlurTimeout);
				});
			}
		};

		_onAttach = function() {
			_isEnabled = true;
		};

		_onDetach = function() {
			_isEnabled = false;
		};

		if (typeof (options.at) === 'string') {
			DD.bpAttach.at(options.at, _onAttach, _onDetach);
		}

		$items.each(function(i, el) {
			_initNavItem(_navid, i, $(el));
		});

		$container.data('ddOffscreen-isInit', true);
	};

	$.extend({
		ddOnscreen: {
			defaults: {
				at: true,
				hasBackground: true,
				backgroundOpacity: 0.75,
				navIdPrefix: 'dropdown-nav-',
				quickKeyboard: true,
				animationType: 'fade',
				durations: {
					show: 300,
					hide: 300,
					hoverTimeout: 250,
					blurTimeout: 250
				},
				triggers: {
					close: 'js-dropdown-close'
				},
				attrs: {
					at: 'data-onscreen-at'
				},
				animations: {
					fade: {
						show: function($container, $link, $dropdown, options, instantly, callback) {
							$dropdown.css({
								'opacity': 0,
								'margin-left': 0
							});

							$dropdown.velocity({
								opacity: 1
							}, {
								duration: (instantly ? 0 : options.durations.show),
								complete: function() {

									$dropdown.attr('style', '');

									if (typeof (callback) === 'function') {
										callback();
									}
								}
							});
						},
						hide: function($container, $link, $dropdown, options, instantly, callback) {
							$dropdown.css({
								'opacity': 1,
								'margin-left': 0
							});

							$dropdown.velocity({
								opacity: 0
							}, {
								duration: (instantly ? 0 : options.durations.hide),
								complete: function() {
									if (typeof (callback) === 'function') {
										callback();
									}
								}
							});
						},
						reset: function($container, $link, $dropdown, options) {
							$container.velocity('stop').removeAttr('style');
							$link.velocity('stop').removeAttr('style');
							$dropdown.velocity('stop').removeAttr('style');
						}
					}
				},
				classes: {
					navList: 'nav',
					navItem: 'nav-item',
					navItemTitle: 'nav-item-title',
					takeFocus: 'js-dropdown-focus',
					dropdown: 'dropdown',
					hover: 'is-hover', // Used to show the dropdown
					hovering: 'is-hovering', // Used to apply style to the link
					isOpening: 'is-opening',
					isClosing: 'is-closing'
				}
			}
		}
	}).fn.extend({
		ddOnscreen: function(options) {

			_checkDependencies();

			options = $.extend(true, {}, $.ddOnscreen.defaults, options);

			return $(this).each(function(i, el) {
				_init(el, options);
			});
		}
	});
})(jQuery, window, document);
