/* =============================================================================
   DD EQUAL HEIGHTS - A jQuery plugin to equalise heights
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/equal-heights.html */
;(function($, window, document, undefined) {

	'use strict';

	var _types = [],
		_calculateItemsHeights,
		_bindTo;

	/**
	 * A function that takes items and properties and aligns the items
	 * equally. Used by $.ddEqualHeights
	 *
	 * @memberof $.ddEqualHeights
	 * @param {Object} $items The list of jQuery DOM items to be looped through
	 * @param {Number|String} itemsPerRow The number of items to match together, or a String of "all" to match all
	 * @param {String} sectionSelector CSS Selector for internal sections to match together. Default is ''
	 * @param {Boolean} isEnabled Tells equalHeights to apply or remove as needed
	 * @private
	 * */
	_calculateItemsHeights = function($items, itemsPerRow, sectionSelector, isEnabled) {
		var maxHeights = [],
			$visibleItems = $items.filter(':visible');

		itemsPerRow = (itemsPerRow === 'all') ? $items.length : itemsPerRow;

		$visibleItems.each(function(itemIndex, el) {
			var $item = $(el),
				row = Math.floor(itemIndex / itemsPerRow),
				$sections = (sectionSelector === '') ? $item : $item.find(sectionSelector);

			if (typeof (maxHeights[row]) === 'undefined') {
				maxHeights[row] = [];
			}

			$sections.each(function(sectionIndex, el) {
				var $section = $(el),
					height;

				// Remove the inline height style, so that only heights from the stylesheet are applied
				$section.css('height', '');

				// Calculate greatest height for this section
				if (isEnabled) {
					height = $section.outerHeight();
					maxHeights[row][sectionIndex] = (!maxHeights[row][sectionIndex] || maxHeights[row][sectionIndex] < height) ? height : maxHeights[row][sectionIndex];
				}
			});
		});

		if (isEnabled) {
			$visibleItems.each(function(itemIndex, el) {
				var $item = $(el),
					row = Math.floor(itemIndex / itemsPerRow),
					$sections = (sectionSelector === '') ? $item : $item.find(sectionSelector),
					sectionIndex = 0;

				$sections.each(function(i, el) {
					var $section = $(el);

					$section.css('height', maxHeights[row][sectionIndex]);

					sectionIndex = sectionIndex + 1;

				});
			});
		}
	};

	/**
	 * Bind the equal heights functionality to a container with a type
	 *
	 * @memberof $.ddEqualHeights
	 * @param {Object} container The DOM element either jQuery or not for the container
	 * @param {String} typeString The equalHeights type to apply to this instance
	 * @private
	 */
	_bindTo = function(container, typeString) {
		var $container = $(container),
			type = typeString || null,
			$items = $([]),
			isEnabled = true,
			config,
			itemsPerRow,
			itemsPerRowAtBp,
			calcHeights,
			resetHeights,
			calcHeightsOnResize,
			registerColumnsAtBP;

		// don't run more than once
		if (typeof ($container.data('ddEqualHeights-isInit')) === 'boolean' && $container.data('ddEqualHeights-isInit') === true) {
			return;
		}

		// get config by looking at the type
		config = $.ddEqualHeights.getType(type);

		// updates the list of items based on the selector in the config
		$items = $container.find(config.itemsSelector);

		// if it's a number  or 'all' use it, if not, assume it's a media query object
		if ((typeof (config.numItemsPerRow) === 'number') || (config.numItemsPerRow === 'all')) {
			itemsPerRow = config.numItemsPerRow;
		} else {
			itemsPerRowAtBp = config.numItemsPerRow;
		}

		if (itemsPerRow === 1) {
			isEnabled = false;
		} else {
			isEnabled = true;
		}

		// calculates the heights for the container's items
		calcHeights = function() {
			$items = $container.find(config.itemsSelector);
			_calculateItemsHeights($items, itemsPerRow, config.sectionSelector, isEnabled);
		};

		// resets the heights - used when only a single item needs to be shown
		resetHeights = function() {
			$items = $container.find(config.itemsSelector);
			_calculateItemsHeights($items, itemsPerRow, config.sectionSelector, false);
		};

		// update the values on resize of the screen - debounced so that it doesn't happen too often
		calcHeightsOnResize = $.debounce(200, function() {
			// Detaching resize event listener while running calcHeights() because IE8 triggers
			// a window.resize event when any element in the DOM is resized
			// http://stackoverflow.com/questions/1852751/window-resize-event-firing-in-internet-explorer
			$(window).off('resize.ddEqualHeights orientationchange.ddEqualHeights', calcHeightsOnResize);
			calcHeights();

			setTimeout(function() {
				$(window).on('resize.ddEqualHeights orientationchange.ddEqualHeights', calcHeightsOnResize);
			}, 1);
		});

		// Other JavaScript modules can active and deactive this module by triggering an event on the container
		$container.off('update.ddEqualHeights').on('update.ddEqualHeights', calcHeights);
		$container.off('reset.ddEqualHeights').on('reset.ddEqualHeights', resetHeights);

		// add a listener for the load event to each image inside the equalheights container
		// then on load, update the heights again
		$container.find('img').one('load.ddEqualHeights', calcHeights).each(function() {
			// if the image is already complete, then trigger the load event manually
			// (this normally happens when the image is cached)
			if (this.complete) {
				$(this).trigger('load.ddEqualHeights');
			}
		});

		// recalculate heights on resize
		$(window)
			.off('resize.ddEqualHeights orientationchange.ddEqualHeights', calcHeightsOnResize)
			.on('resize.ddEqualHeights orientationchange.ddEqualHeights', calcHeightsOnResize);

		// calculate the height on page load
		calcHeights();

		// dynamically generate the list of items per row from an object of DD.bp breakpoints
		registerColumnsAtBP = function(key) {
			// only run if DD.bp is available
			if (typeof (DD) === 'undefined' || typeof (DD.bp) === 'undefined') {
				console.warn('$.ddEqualHeights: DD.bp is required to use the itemsPerRow at breakpoints feature');
				return;
			}

			// change the number of items per row depending on the breakpoint
			var updateItemsPerRow = function() {
				itemsPerRow = itemsPerRowAtBp[key];

				if (itemsPerRow === 1) {
					isEnabled = false;
				}  else {
					isEnabled = true;
				}

				$container.trigger('update.ddEqualHeights');
			};

			// bind the breakpoint listener for each key
			enquire.register(DD.bp.get(key), {
				match: updateItemsPerRow
			});

		};

		if (itemsPerRowAtBp !== undefined) {
			for (var key in itemsPerRowAtBp) {
				if (itemsPerRowAtBp.hasOwnProperty(key)) {
					registerColumnsAtBP(key);
				}
			}
		}

		// avoid running twice
		$container.data('ddEqualHeights-isInit', true);
	};

	$.extend({
		ddEqualHeights: {
			defaults: {
				typeAttr: 'data-heights-type'
			},

			typeDefaults: {
				itemsSelector: '> *',
				sectionSelector: '',
				numItemsPerRow: 'all'
			},

			/**
			 * Loops through the config types and finds the appropriate type
			 * if it can't find the type it uses the defaults from
			 * $.ddEqualHeights.typeDefaults instead
			 *
			 * @memberof $.ddEqualHeights
			 * @param {string} name The unique identifier passed through in the data-heights-type attribute
			 */
			getType: function(name) {
				var config = {},
					matchedConfig;

				if (name === null) {
					return $.ddEqualHeights.typeDefaults;
				}

				for (var names in _types) {
					if (_types.hasOwnProperty(names) && names === name) {
						matchedConfig = _types[names];
					}
				}

				// fill out any unfilled options with the defaults
				$.extend(config, $.ddEqualHeights.typeDefaults, matchedConfig);

				if (config) {
					for (var key in config) {
						// if it's a function, convert it to a string instead
						if (config.hasOwnProperty(key) && typeof (config[key]) === 'function') {
							config[key] = config[key]();
						}
					}

					return config;
				}

				console.warn('$.ddEqualHeights: Dynamic heights type "' + name + '" not found. Using defaults.');

				return $.ddEqualHeights.typeDefaults;
			},

			/**
			 * Adds a custom type to the list of types
			 *
			 * @memberof $.ddEqualHeights
			 * @param {string} name The unique identifier of the config
			 * @param {object} config The config object to be used
			 *
			 * @example
			 * $.fn.ddEqualHeights.addType('feature-item', {
			 *     itemsSelector: '.item',
			 *     sectionSelector: '.section',
			 *     numItemsPerRow: 2
			 * });
			 */
			addType: function(name, config) {
				_types[name] = config;
			}
		}
	}).fn.extend({
		/**
		 * @example
		 * $('[data-heights-type]').ddEqualHeights();
		 *
		 * @example
		 * $('[data-ddeqh-type]').ddEqualHeights({
		 *     typeAttr: 'data-ddeqh-type'
		 * });
		 */
		ddEqualHeights: function(options) {
			options = $.extend({}, $.ddEqualHeights.defaults, options);

			return $(this).each(function(i, el) {
				var type = $(el).attr(options.typeAttr);

				_bindTo(this, type);
			});
		}
	});
})(jQuery, window, document);
