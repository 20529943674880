/* =============================================================================
   DD RESPONSIVE TABLE - A jQuery plugin for making tables responsive
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/responsive-table.html */
;(function($, window, document, undefined) {

	'use strict';

	var	_init;

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddResponsiveTable
	 * @param {Object} table The original table to be enhanced
	 * @param {Object} options The options passed to the plugin
	 * @param {Number} tableIndex Index of the item on the page to help generate unique ids
	 * @private
	 * */
	_init = function(table, options, tableIndex) {
		var $table = $(table),
			$responsiveTable,
			$scrollableContainer,
			_scrollable,
			_columnToggle,
			_handleResize;

		// don't run more than once
		if (typeof ($table.data('ddResponsiveTable-isInit')) === 'boolean' && $table.data('ddResponsiveTable-isInit') === true) {
			return;
		}

		// allow the table to scroll left/right with shadows appearing/disappearing
		// depending on how close to the edge of the table the scrollable window is.
		_scrollable = (function() {
			var init,
				onScrollHandler,
				checkIfHasScroll;

			// display or hide the shadows when the scroll is close to the edge
			onScrollHandler = function() {
				var scrollableWidth = $table.outerWidth() - $scrollableContainer.outerWidth(),
					amountScrolled = Math.floor(($scrollableContainer.scrollLeft() / scrollableWidth) * 100);

				// scroll left shadow depending how close it is to the start of the table
				if (amountScrolled < 10) {
					$scrollableContainer.find('.' + options.classes.scrollable.shadowBefore).css({
						'margin-left': parseInt(10 - amountScrolled, 10) * -1 + 'px'
					});
				} else {
					$scrollableContainer.find('.' + options.classes.scrollable.shadowBefore).css({
						'margin-left': 0
					});
				}

				// scroll right shadow depending how close it is to the end of the table
				if (amountScrolled > 90) {
					$scrollableContainer.find('.' + options.classes.scrollable.shadowAfter).css({
						'margin-left': parseInt(90 - amountScrolled, 10) * -1 + 'px'
					});
				} else {
					$scrollableContainer.find('.' + options.classes.scrollable.shadowAfter).css({
						'margin-left': 0
					});
				}
			};

			// the table will only need scrolling if the table inside is
			// larger than the outside container
			checkIfHasScroll = function() {
				if ($table.outerWidth() > $scrollableContainer.outerWidth()) {
					$responsiveTable.addClass(options.classes.scrollable.hasScroll);
					onScrollHandler();
					$scrollableContainer.on('scroll.ddResponsiveTable', onScrollHandler);
				} else {
					$responsiveTable.removeClass(options.classes.scrollable.hasScroll);
					$scrollableContainer.off('scroll.ddResponsiveTable', onScrollHandler);
				}
			};

			// initialiser
			init = function() {
				$scrollableContainer = $('<div/>', {
					class: options.classes.scrollable.container
				});

				$responsiveTable.append($scrollableContainer).insertBefore($table);

				$table.appendTo($scrollableContainer);
				$scrollableContainer.prepend($('<span/>', {
					class: options.classes.scrollable.shadowBefore
				})).append($('<span/>', {
					class: options.classes.scrollable.shadowAfter
				}));
			};

			return {
				onScrollHandler: onScrollHandler,
				checkIfHasScroll: checkIfHasScroll,
				init: init
			};
		})();

		// adds toggleable columns on smaller screens so columns can dynamically be disabled,
		// making the table easier to read
		_columnToggle = (function() {
			var columnIndex = 0,
				$menuList,
				$menuContents,
				$menuButton,
				$menuContainer,
				_parseTable,
				_closeMenu,
				_onMenuButtonClick,
				_getColumnClasses,
				getInputs,
				init;

			// close the popup menu
			_closeMenu = function() {
				$(document).off('click.ddResponsiveTable touchStart.ddResponsiveTable');
				$menuContents.addClass(options.classes.columnToggle.isHidden);
			};

			// when the menu button is clicked
			_onMenuButtonClick = function(event) {
				event.preventDefault();

				if ($menuContents.hasClass(options.classes.columnToggle.isHidden)) {
					$menuContents.removeClass(options.classes.columnToggle.isHidden);

					$(document).on('click.ddResponsiveTable touchStart.ddResponsiveTable', function(event) {
						if ($menuContainer.find(event.target).length === 0) {
							_closeMenu();
						}
					});
				} else {
					_closeMenu();
				}
			};

			// only return classes with the appropriate prefix (by default 'column-')
			_getColumnClasses = function(classes) {
				var classesArray = [];

				if (!classes) {
					return '';
				}

				classesArray = classes.split(' ');
				classesArray = $.grep(classesArray, function(className) {
					return (className.indexOf(options.classes.columnToggle.columns.prefix) === 0);
				});

				return classesArray.join(' ');
			};

			// read through the table and understand what the structure is so we can apply the classes
			// to all of the table columns, add the menu and create the correct list items and add the
			// toggleable functionality
			_parseTable = function() {
				$table.find('thead th').each(function(thIndex, el) {
					var $th = $(el),
						id = $th.attr('id'),
						colspan = parseInt($th.attr('colspan'), 10) || 1,
						classes = _getColumnClasses($th.attr('class'));

					// Assign a unique ID to each <th> that doesn't already have one
					if (!id) {
						id = 'responsive-table-' + tableIndex + '-col-' + thIndex;
						$th.attr('id', id);
					}

					// Loop through each row to assign a 'headers' attribute and any classes
					// (column-persist, column-important, column-optional) to the matching cell
					$table.find('tbody tr, tfoot tr').each(function() {
						// The slice() function is used to select multiple cells if the <th> had a colspan
						var $cells = $(this).find('th, td').slice(columnIndex, columnIndex + colspan);

						$cells.attr('headers', id);

						if (classes) {
							$cells.addClass(classes);
						}
					});

					// Create the menu hide/show toggles
					if (!$th.hasClass(options.classes.columnToggle.columns.prefix + options.classes.columnToggle.columns.persist)) {
						var toggleId = 'responsive-table-' + tableIndex + '-toggle-col-' + thIndex,
							$input;

						$input = $('<input/>', {
							type: 'checkbox',
							name: 'responsive-table-' + tableIndex + '-toggle-cols',
							id: toggleId,
							value: id
						});

						$menuList.append($('<li/>').append($input).append($('<label/>', {
							for: toggleId,
							text: $th.text()
						})));

						// bind events to the inputs to toggle the display of the columns
						$input.on('change.ddResponsiveTable', function() {
							var val = $input.val(),
								$cols = $(document.getElementById(val)).add($('[headers=' + val + ']'));

							if ($input.is(':checked')) {
								$cols.removeClass(options.classes.columnToggle.columns.forceHide)
									.addClass(options.classes.columnToggle.columns.forceShow);
							} else {
								$cols.addClass(options.classes.columnToggle.columns.forceHide)
									.removeClass(options.classes.columnToggle.columns.forceShow);
							}

							if (options.scrollable) {
								_scrollable.checkIfHasScroll();
							}
						});

						// update the menu inputs when the page size changes
						$input.on('updateInputs.ddResponsiveTable', function() {
							if ($th.css('display') === 'table-cell') {
								$input.attr('checked', true);
							} else {
								$input.attr('checked', false);
							}
						});
					}

					columnIndex += colspan;
				});
			};

			// get the inputs from the menu
			getInputs = function() {
				return $menuList.find('input');
			};

			// initialiser
			init = function() {
				// create the dynamic menu
				$menuList = $('<ul/>');
				$menuContents = $('<div/>', {
					class: options.classes.columnToggle.menuContents + ' ' + options.classes.columnToggle.isHidden
				}).append($menuList);

				$menuButton = $('<button/>', {
					class: options.classes.columnToggle.menuButton,
					text: options.columnToggleText
				});

				$menuContainer = $('<div/>', {
					class: options.classes.columnToggle.menu
				}).append($menuButton).append($menuContents);

				// read through the table
				_parseTable();

				// functionality for the menu button
				$menuButton.on('click.ddResponsiveTable touchStart.ddResponsiveTable', _onMenuButtonClick);

				// add to the page
				$responsiveTable.before($menuContainer);
				$responsiveTable.addClass(options.classes.columnToggle.hasColumnToggle);
			};

			return {
				getInputs: getInputs,
				init: init
			};
		})();

		// create the table wrapper for responsive tables
		$responsiveTable = $('<div/>', {
			class: options.classes.container
		});

		if (options.scrollable) {
			// implement scrollable table
			_scrollable.init();
		} else {
			// if the scrollable class doesn't setup the wrapper for us,
			// we'll setup a version of it now
			$table.wrap($responsiveTable);
			$responsiveTable = $table.parent();
		}

		if (options.columnToggle) {
			// implement column toggle table
			_columnToggle.init();
		}

		// on resize of the page check for updates to the table
		_handleResize = function() {
			if (options.scrollable) {
				_scrollable.checkIfHasScroll();
			}

			if (options.columnToggle && _columnToggle.getInputs().length > 0) {
				_columnToggle.getInputs().trigger('updateInputs.ddResponsiveTable');
			}
		};

		// only bind the resize event if it's actually needed
		if (options.scrollable || (options.columnToggle && _columnToggle.getInputs().length > 0)) {
			$(window).on('resize.ddResponsiveTable orientationchange.ddResponsiveTable', $.throttle(100, _handleResize));
			_handleResize();
		}

		// the table has been initialised - this will ensure that it can't be run multiple times
		$table.data('ddResponsiveTable-isInit', true);
	};

	$.extend({
		ddResponsiveTable: {
			defaults: {
				scrollable: true,
				columnToggle: false,
				columnToggleText: 'Show/hide columns',
				classes: {
					container: 'responsive-table',
					scrollable: {
						container: 'table-scrollable',
						shadowBefore: 'shadow-before',
						shadowAfter: 'shadow-after',
						hasScroll: 'has-scroll'
					},
					columnToggle: {
						menu: 'responsive-table-toggle-menu',
						menuContents: 'responsive-table-toggle-menu-list',
						menuButton: 'responsive-table-toggle-menu-btn',
						columns: {
							prefix: 'column-',
							persist: 'persist',
							forceShow: 'column-force-show',
							forceHide: 'column-force-hide'
						},
						isHidden: 'is-hidden',
						hasColumnToggle: 'has-column-toggle'
					}
				}
			}
		}
	}).fn.extend({
		ddResponsiveTable: function(options) {
			options = $.extend(true, {}, $.ddResponsiveTable.defaults, options);

			return $(this).each(function(i, el) {
				_init(el, options, i);
			});
		}
	});
})(jQuery, window, document);
