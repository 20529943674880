/* =============================================================================
   DD AUTOCOMPLETE - A jQuery plugin for autocomplete
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/autocomplete.html */
;(function($, window, document, undefined) {

	'use strict';

	var	KEYCODE,
		TYPES,
		_checkForProperWord,
		_updateOptionsFromDOM,
		_init;

	KEYCODE = {
		UP: 38,
		DOWN: 40,
		TAB: 9,
		ENTER: 13
	};

	TYPES = {
		SIMPLE: 'simple',
		LINKS: 'links'
	};

	/**
	 * Check the word searched for to ensure that it's a valid search term
	 *
	 * @memberof $.ddAutocomplete
	 * @param {String} searchTerm The search term string to check
	 * @param {Object} options The options passed to the plugin
	 * @private
	 * */
	_checkForProperWord = function(searchTerm, options) {
		// Check for a word of 3 characters or more
		var wordArray = searchTerm.split(' ');

		if (wordArray.length > 1) {
			// has more than one word
			var isValid = false;

			for (var i = 0, len = wordArray.length; i < len; i += 1) {
				if (wordArray[i].length >= options.ajaxMinChars) {
					isValid = true;
					break;
				}
			}

			return isValid;
		}

		if (searchTerm.length >= options.ajaxMinChars) {
			return true;
		}

		return false;
	};

	/**
	 * Look at the container to see if there are any DOM attributes that
	 * can override base options
	 *
	 * @memberof $.ddAutocomplete
	 * @param {Object} $container The jQuery element to group
	 * @param {Object} options The options passed to the plugin
	 * @private
	 * */
	_updateOptionsFromDOM = function($container, options) {
		var updatedOptions = $.extend(true, {}, options);

		updatedOptions.url = $container.attr(options.attrs.url) || options.url;
		updatedOptions.type = $container.attr(options.attrs.type) || options.type;

		return updatedOptions;
	};

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddAutocomplete
	 * @param {Object} button The container of the autocomplete
	 * @param {Object} options The options passed to the plugin
	 * @private
	 * */
	_init = function(container, options) {
		var $container = $(container),
			_isDisplayed = false,
			_isFirstFocus = true,
			$searchField = $container.find('.' + options.classes.searchField),
			_resultsId = $searchField.attr('id') + options.resultsIdSuffix,
			_previousSearchTerm = '',
			_templates,
			$searchResults,
			$searchResultsList,
			_containerTimeout,
			_ajaxTimeout,
			_jqXHR,
			_selectAndClose,
			_keyboardActions,
			_showResults,
			_clearResults,
			_abortCurrentAjax,
			_hideResults,
			_getDataFromAjax,
			_onSearchFieldFocus,
			_getSearchResults,
			_onSearchFieldKeyup;

		// don't run more than once
		if (typeof ($container.data('ddAutocomplete-isInit')) === 'boolean' && $container.data('ddAutocomplete-isInit') === true) {
			return;
		}

		// update the ec item options based on the DOM
		options = _updateOptionsFromDOM($container, options);

		_templates = options.templates.simple;

		if (options.type === TYPES.LINKS) {
			_templates = options.templates.links;
		}

		var $resultsContainer = _templates.container(_resultsId, options);
		$container.append($resultsContainer);

		$searchResults = $(document.getElementById(_resultsId));
		$searchResultsList = $searchResults.find('.' + options.classes.list);

		// add attributes
		$searchField.attr({
			autocomplete: 'off',
			'aria-controls': _resultsId,
			role: 'combobox',
			'aria-describedby': 'search-updates'
		});

		$searchResults.attr({
			tabindex: '-1'
		});

		_selectAndClose = function(event) {
			var $selectedItem = $searchResults.find('.' + options.classes.isSelected);

			if (event) {
				event.preventDefault();
				$selectedItem = $(event.currentTarget);
			}

			if ($selectedItem.length > 0) {
				$searchField.val($selectedItem.text());
			}

			setTimeout(function() {
				_hideResults(true);
			}, 20);

			$searchField.trigger('selectAndClose.ddAutocomplete', [$selectedItem.text()]);
			$searchField.focus();
		};

		_keyboardActions = function(event) {
			if (_isDisplayed) {
				if (options.type === TYPES.SIMPLE && event.which === KEYCODE.ENTER) {
					if ($searchField.is(':focus')) {
						var resultsLength = $searchResultsList.find('li').length;

						if (resultsLength === 0) {
							// there are no results - just let it behave like normal
							return;
						}
					}

					event.preventDefault();
					_selectAndClose();

					return;
				}

				if ($searchField.is(':focus') && event.which === KEYCODE.TAB && !event.shiftKey) {
					// don't want users to tab into the results panel if it's a simple version
					if (options.type === TYPES.SIMPLE) {
						_hideResults(true);
						return;
					}
					event.preventDefault();
					$searchResults.focus();

					$('.js-autocomplete-results-submit').blur(function() {
						$('.search-close').focus();
						// turn off event listener after focus
						$('.js-autocomplete-results-submit').off('blur');
					});
					return;
				}

				if (event.which === KEYCODE.UP || event.which === KEYCODE.DOWN) {
					event.preventDefault();

					var $searchResultsItems = $searchResults.find('.' + options.classes.resultsItem),
						maxIndex = $searchResultsItems.length,
						newIndex;

					if (maxIndex === 0) {
						return;
					}

					var $selectedItem = $searchResults.find('.' + options.classes.isSelected);

					if ($selectedItem.length > 0) {
						var currentIndex = 0;

						$searchResultsItems.each(function(i, el) {
							if ($(el).hasClass(options.classes.isSelected)) {
								currentIndex = i;
							}
						});

						if (event.which === KEYCODE.UP) {
							newIndex = (currentIndex === 0) ? maxIndex : currentIndex - 1;
						} else if (event.which === KEYCODE.DOWN) {
							newIndex = (currentIndex === maxIndex) ? 0 : currentIndex + 1;
						}

					} else {
						newIndex = (event.which === KEYCODE.UP) ? maxIndex - 1 : 0;
						$searchField.data('ddAutocomplete-val', $searchField.val());
					}

					$searchResultsItems.removeClass(options.classes.isSelected);
					$selectedItem = $searchResultsItems.eq(newIndex).addClass(options.classes.isSelected);

					if (options.type === TYPES.LINKS) {
						if (newIndex === maxIndex) {
							$searchField.focus();
						} else {
							$selectedItem.focus();
						}
					} else {
						var text = (newIndex === maxIndex) ? $searchField.data('ddAutocomplete-val') : $selectedItem.text();
						$searchField.val(text);
					}
				}
			}
		};

		_showResults = function() {
			_isDisplayed = true;

			clearTimeout(_containerTimeout);

			$container.addClass(options.classes.isActive);

			$(document).off('keydown.ddAutocomplete', _keyboardActions)
				.on('keydown.ddAutocomplete', _keyboardActions);

			var searchTerm = $searchField.val();
			var isValidSearchTerm = _checkForProperWord(searchTerm, options);
			if (!isValidSearchTerm) {
				$('.autocomplete-results').hide();
				$('.js-autocomplete-results-submit').hide();
			}
		};

		_abortCurrentAjax = function() {
			// abort any current ajax timeouts
			clearTimeout(_ajaxTimeout);

			// abort any in progress ajax calls
			if (_jqXHR) {
				_jqXHR.abort();
			}
		};

		_clearResults = function() {
			_abortCurrentAjax();
			$searchResultsList.html('');
			_previousSearchTerm = '';
		};

		_hideResults = function(instant) {
			var hideAfterTimeout = function() {
				_abortCurrentAjax();

				// remove keyboard event listeners
				$(document).off('keydown.ddAutocomplete', _keyboardActions);

				_isDisplayed = false;

				// it's not loading anymore
				$container.removeClass(options.classes.isLoading).removeClass(options.classes.isActive);
			};

			clearTimeout(_containerTimeout);

			if (typeof (instant) === 'boolean' && instant === true) {
				hideAfterTimeout();
			} else {
				_containerTimeout = setTimeout(hideAfterTimeout, options.durations.containerTimeout);
			}
		};

		_getDataFromAjax = function(searchTerm) {
			if (_previousSearchTerm === searchTerm) {
				$container.removeClass(options.classes.isLoading);

				// no need to get new data because it's the same search as last time
				return;
			}

			_abortCurrentAjax();

			var onAjaxDone,
				onAjaxFail;

			onAjaxDone = function(data, textStatus) {
				var results,
					resultsCount;

				// trigger event for an external listener
				$searchField.trigger('ajaxSuccess.ddAutocomplete', [data]);

				if (textStatus === 'success') {
					_previousSearchTerm = searchTerm;
				}

				$container.removeClass(options.classes.isLoading);

				results = options.parseResults(data);
				resultsCount = options.countResults(data);

				if (results.length !== 0) {
					$('.js-autocomplete-results-submit').show();
					$('.autocomplete-results').show();
				} else {
					$('.js-autocomplete-results-submit').hide();
				};

				if (typeof (options.highlightSearchTerm) === 'function') {
					results = options.highlightSearchTerm(results, searchTerm);
				}

				// load data
				$searchResultsList.html(_templates.list(results, options, resultsCount));

				var $searchResultsInteractions = $searchResults
					.add($searchResults.find('.' + options.classes.searchResultsSubmit))
					.add($searchResults.find('.' + options.classes.resultsItem));

				$searchResultsInteractions.off('.ddAutocomplete')
					.on('focus.ddAutocomplete', function() {
						clearTimeout(_containerTimeout);
					});

				if (options.type === TYPES.SIMPLE) {
					$searchResults.find('.' + options.classes.resultsItem)
						.on('click.ddAutocomplete', function(event) {
							event.stopPropagation();
							_selectAndClose(event);
						});
				}

				_jqXHR = null;
			};

			onAjaxFail = function(jqXHR, textStatus, errorThrown) {
				if (textStatus !== 'abort') {
					console.warn('$.ddAutocomplete: AJAX error', jqXHR, textStatus, errorThrown);
					$container.removeClass(options.classes.isLoading);

					_jqXHR = null;
				}
			};

			// do the AJAX load
			_jqXHR = $.ajax({
				url: options.url,
				data: {
					q: searchTerm
				}
			}).done(onAjaxDone).fail(onAjaxFail);
		};

		_getSearchResults = function(searchTerm) {
			// validate and replace searchTerm string
			searchTerm = searchTerm && $.trim(searchTerm.replace(/</gi, '&lt;').replace(/>/gi, '&gt;'));

			// add the search term to the results panel if required
			$searchResults.find('.' + options.classes.searchResultsTerm).text(searchTerm);

			if (searchTerm && _checkForProperWord(searchTerm, options)) {
				$container.addClass(options.classes.isLoading);
				_abortCurrentAjax();

				clearTimeout(_ajaxTimeout);

				_ajaxTimeout = setTimeout(function() {
					_getDataFromAjax(searchTerm);
				}, options.durations.ajaxTimeout);
			} else {
				_clearResults();
			}
		};

		_onSearchFieldFocus = function() {
			var searchTerm = $searchField.val();

			if (searchTerm.length >= options.showMinChars) {
				if (_isFirstFocus) {
					_getSearchResults(searchTerm);
					_showResults();

					_isFirstFocus = false;
				} else {
					_showResults();
				}
			}
		};

		_onSearchFieldKeyup = function(event) {
			var searchTerm = $searchField.val();

			if (options.type === TYPES.SIMPLE && event.which === KEYCODE.ENTER) {
				_clearResults();
				_hideResults(true);
				return;
			}

			if (_isDisplayed && (event.which === KEYCODE.UP || event.which === KEYCODE.DOWN)) {
				return;
			}

			if (searchTerm.length < options.showMinChars) {
				_clearResults();
				_hideResults(true);
				return;
			}

			_showResults();
			_getSearchResults(searchTerm);
		};

		// add events
		$searchField.off('.ddAutocomplete')
			.on('focus.ddAutocomplete', _onSearchFieldFocus)
			.on('keyup.ddAutocomplete', _onSearchFieldKeyup)
			.on('blur.ddAutocomplete', _hideResults);

		// A links type will sometimes still have a search submit button inside the results
		if (options.type === TYPES.LINKS) {
			var $submitButton = options.$submitButton || $container.find('.' + options.classes.submitButton);

			if ($submitButton.length === 0) {
				return;
			}

			$searchResults.find('.' + options.classes.searchResultsSubmit).on('click.ddAutocomplete', function(event) {
				event.preventDefault();
				$submitButton.trigger('click.ddAutocomplete');
			});
		}

		// on completed initialisation
		$container.data('ddAutocomplete-isInit', true);
	};

	$.extend({
		ddAutocomplete: {
			defaults: {
				showMinChars: 1,
				ajaxMinChars: 3,
				url: null,
				type: 'simple',
				resultsIdSuffix: '-autocomplete-results',
				$submitButton: null,
				attrs: {
					url: 'data-autocomplete-url',
					type: 'data-autocomplete-type'
				},
				durations: {
					containerTimeout: 100,
					ajaxTimeout: 500
				},
				classes: {
					results: 'autocomplete-results',
					list: 'autocomplete-list',
					resultsItem: 'autocomplete-results-item',
					resultsItemTitle: 'autocomplete-results-item-title',
					resultsFooter: 'autocomplete-results-footer',
					searchResultsSubmit: 'js-autocomplete-results-submit cta is-small',
					searchResultsTerm: 'js-autocomplete-results-searchterm',
					searchField: 'js-autocomplete-input',
					submitButton: 'js-autocomplete-submit',
					isLoading: 'is-loading',
					isActive: 'is-active',
					isSelected: 'is-selected'
				},
				parseResults: function(data) {
					var results = [];

					if (data.autocomplete && typeof (data.autocomplete) === 'object') {
						for (var i = 0, len = data.autocomplete.length; i < len; i += 1) {
							var result = data.autocomplete[i];

							if (typeof (result) === 'string') {
								result = {
									title: result
								};
							}

							results.push(result);
						}
					}

					return results;
				},
				countResults: function(data) {
					var resultsCount = data.total;
					return resultsCount;
				},
				highlightSearchTerm: function(results, searchTerm) {
					var searchTerms = searchTerm.replace(',', '').split(' '),
						isFirstSearchTerm = true,
						searchTermRegEx = '',
						reg,
						highlightMatch;

					for (var i = 0, len = searchTerms.length; i < len; i += 1) {
						var matchedTerm = searchTerms[i];

						// if the item is just spaces or is blank, ignore
						if (matchedTerm.replace(/\s|\./g, '').length > 0) {
							if (isFirstSearchTerm) {
								isFirstSearchTerm = false;
							} else {
								searchTermRegEx += '|';
							}

							searchTermRegEx += '\\b' + matchedTerm;
						}
					}

					reg = new RegExp(searchTermRegEx, 'igm');

					highlightMatch = function(string) {
						// make the search term bold in the results
						if (string.match(reg) !== null) {
							string = string.replace(reg, '<strong>$&</strong>');
						}

						return string;
					};

					for (var j = 0, resultsLen = results.length; j < resultsLen; j += 1) {
						results[j].title = highlightMatch(results[j].title);

						if (results[j].description) {
							results[j].description = highlightMatch(results[j].description);
						}
					}

					return results;
				},
				templates: {
					simple: {
						container: function(id, options) {
							var $container;

							$container = $('<div/>', {
								id: id,
								class: options.classes.results,
								html: $('<div/>', {
									class: options.classes.list
								})
							});

							return $container;
						},
						list: function(results, options) {
							var $list = $('<ul/>');

							for (var i = 0, len = results.length; i < len; i += 1) {
								var item = results[i],
									$listItem;

								$listItem = $('<li/>', {
									html: $('<button/>', {
										role: 'button',
										class: options.classes.resultsItem,
										html: item.title
									})
								});

								$list.append($listItem);
							}

							return $list;
						}
					},
					links: {
						container: function(id, options) {
							var $container;

							$container = $('<div/>', {
								id: id,
								class: options.classes.results,
								html: $('<div/>', {
									class: options.classes.list
								})
							});

							$container.append($('<div/>', {
								class: options.classes.resultsFooter,
								html: $('<button/>', {
									class: options.classes.searchResultsSubmit + ' ' + options.classes.resultsItem,
									html: 'See all results'
								})
							}));

							return $container;
						},
						list: function(results, options, resultsCount) {
							var $result = $('<div/>',{
								id: 'search-updates'
							});
							var $list = $('<ul/>');

							var resultsPreview = 'Showing ' + results.length + ' of ' + resultsCount + ' results';

							$result.prepend($('<div/>',{
								class: 'autocomplete-results-preview',
								html: resultsPreview,
								'aria-live': 'assertive',
								'aria-atomic': 'true'
							}));

							for (var i = 0, len = results.length; i < len; i += 1) {
								var item = results[i],
									$listItem;

								$listItem = $('<li/>', {
									html: $('<a/>', {
										role: 'button',
										href: item.href,
										class: options.classes.resultsItem
									})
								});

								if (item.description) {
									$listItem.find('a').append($('<span/>', {
										class: options.classes.resultsItemTitle,
										html: item.title
									})).append($('<span/>', {
										html: item.description
									}));
								} else {
									$listItem.find('a').append($('<span/>', {
										class: options.classes.resultsItemTitle,
										html: item.title
									}));
								}

								$list.append($listItem);
							}

							$result.append($list);
							return $result;
						}
					}
				}
			}
		}
	}).fn.extend({
		ddAutocomplete: function(options) {
			options = $.extend(true, {}, $.ddAutocomplete.defaults, options);

			return $(this).each(function(i, el) {
				_init(el, options);
			});
		}
	});
})(jQuery, window, document);
