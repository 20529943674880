/**
 * Deloitte Digital global namespace for modules
 * @namespace DD
 */
;(function($, window, document, undefined) {
	'use strict';

	window.DD = window.DD || {};

	/*
		Add ability to check if an element is focusable or tabbable to jQuery selectors.
		Taken from jQuery UI
	*/

	var _visible,
		_focusable;

	// helper functions
	_visible = function(element) {
		return $.expr.filters.visible(element) && !$(element).parents().addBack().filter(function() {
			return $.css(this, 'visibility') === 'hidden';
		}).length;
	};

	_focusable = function(element, isTabIndexNotNaN) {
		var map, mapName, img, nodeName = element.nodeName.toLowerCase();
		if (nodeName === 'area') {
			map = element.parentNode;
			mapName = map.name;
			if (!element.href || !mapName || map.nodeName.toLowerCase() !== 'map') {
				return false;
			}
			img = $('img[usemap=#' + mapName + ']')[0];
			return !!img && _visible(img);
		}
		return (/input|select|textarea|button|object/.test(nodeName) ? !element.disabled :
			nodeName === 'a' ?
			element.href || isTabIndexNotNaN :
			isTabIndexNotNaN) &&
		// the element and all of its ancestors must be visible
		_visible(element);
	};

	$.extend($.expr[':'], {
		data: $.expr.createPseudo ? $.expr.createPseudo(function(dataName) {
			return function(elem) {
				return !!$.data(elem, dataName);
			};
		}) :
		// support: jQuery <1.8
		function(elem, i, match) {
			return !!$.data(elem, match[3]);
		},

		focusable: function(element) {
			return _focusable(element, !isNaN($.attr(element, 'tabindex')));
		},

		tabbable: function(element) {
			var tabIndex = $.attr(element, 'tabindex'),
				isTabIndexNaN = isNaN(tabIndex);
			return (isTabIndexNaN || tabIndex >= 0) && _focusable(element, !isTabIndexNaN);
		}
	});

	/**
	 * Accessibility (abbreviated to a11y) for Deloitte Digital projects
	 *
	 * @namespace a11y
	 * @memberof DD
	 * @version 1.0.0
	 * @copyright 2015 Deloitte Digital Australia - http://www.deloittedigital.com/au
	 * @author Deloitte Digital Australia deloittedigital@deloitte.com.au
	 */
	window.DD.a11y = (function() {
		var KEYCODE,
			onEscape,
			onClickOutside,
			tabInsideContainer,
			ariaHideOthers;

		KEYCODE = {
			TAB: 9,
			ESC: 27
		};

		/**
		 * Bind an event to the ESC keypress - useful for modal windows,
		 * or panels that need to be closed with ESC
		 *
		 * @namespace onEscape
		 * @memberof DD.a11y
		 */
		onEscape = (function() {
			var _callback = null,
				_runCallbackOnEscape,
				set,
				unset;

			/**
			 * Checks for the ESC keypress and validates and runs the callback.
			 *
			 * @memberof DD.a11y.onEscape
			 * @param  {Object} event jQuery Event passed through from the keyup event
			 * @private
			 */
			_runCallbackOnEscape = function(event) {
				if (!event.isDefaultPrevented() && event.keyCode && event.keyCode === KEYCODE.ESC) {
					if (typeof (_callback) === 'function') {
						_callback();
					}
				}
			};

			/**
			 * Sets a callback and the event listeners for the keyup event. Only one
			 * event can be registered at the one time. Unset is automatically run before
			 * setting a new event.
			 *
			 * @memberof DD.a11y.onEscape
			 * @param  {Function} callback Function to be run
			 */
			set = function(callback) {
				unset();

				_callback = callback;
				$(document).on('keyup.accessibleOnEscape', _runCallbackOnEscape);
			};

			/**
			 * Unset the event listener, recommended to call this event inside the callback
			 * that is passed through to the `set` function.
			 *
			 * @memberof DD.a11y.onEscape
			 */
			unset = function() {
				_callback = null;
				$(document).off('keyup.accessibleOnEscape', _runCallbackOnEscape);
			};

			return {
				set: set,
				unset: unset
			};

		}());

		/**
		 * Bind an event to when you click outside of a specified area
		 *
		 * @namespace onClickOutside
		 * @memberof DD.a11y
		 */
		onClickOutside = (function() {
			var _container = null,
				_callback = null,
				_runCallbackOnClickOutside,
				set,
				unset;

			/**
			 * Checks for the ESC keypress and validates and runs the callback.
			 *
			 * @memberof DD.a11y.onClickOutside
			 * @param  {Object} event jQuery Event passed through from the keyup event
			 * @private
			 */
			_runCallbackOnClickOutside = function(event) {
				var origEvent = event.originalEvent;
				origEvent.stopPropagation();

				if ($(event.target).closest(_container).length === 0) {
					event.preventDefault();
					origEvent.preventDefault();

					if (typeof (_callback) === 'function') {
						_callback();
					}
				}
			};

			/**
			 * Sets a callback and the event listeners for the document click event. Only one
			 * event can be registered at the one time. Unset is automatically run before
			 * setting a new event.
			 *
			 * @memberof DD.a11y.onClickOutside
			 * @param  {String|Object} container DOM Element, jQuery Element or selector of the element to click outside of
			 * @param  {Function} callback Function to be run
			 */
			set = function(container, callback) {
				unset();

				_callback = callback;
				_container = container;

				$(document).on('click.accessibleOnClickOutside', _runCallbackOnClickOutside);
			};

			/**
			 * Unset the event listener, recommended to call this event inside the callback
			 * that is passed through to the `set` function.
			 *
			 * @memberof DD.a11y.onClickOutside
			 */
			unset = function() {
				_callback = null;
				$(document).off('click.accessibleOnClickOutside', _runCallbackOnClickOutside);
			};

			return {
				set: set,
				unset: unset
			};

		}());

		/**
		 * Force the keyboard tabbing to only be able to take place inside the
		 * specific container. This is useful for modal windows or when you
		 * need to lock user tabbing inside an area where they shouldn't be
		 * able to interact outside of it.
		 *
		 * @namespace tabInsideContainer
		 * @memberof DD.a11y
		 */
		tabInsideContainer = (function() {
			var _isSet = false,
				_$container,
				set,
				unset;

			/**
			 * Sets `keydown` event listeners on the first and last tabbable element
			 * in the container to tab to each other instead of the default action of
			 * tabbing outside of the container entirely.
			 *
			 * @memberof DD.a11y.tabInsideContainer
			 * @param  {String|Object} container DOM Element, jQuery Element or selector to be used as the bounding container
			 * @param  {Boolean} tabToContainer Determine if we should automatically tab to the first item or not
			 */
			set = function(container, tabToContainer) {
				if (_isSet) {
					console.warn('DD.a11y: tabInsideContainer has already been set. Unsetting now');
					unset();
				}

				_$container = $(container);

				// keyboard binding for accessibility
				var $firstFocusable = _$container.find(':tabbable:first').eq(0),
					$lastFocusable = _$container.find(':tabbable:last').eq(0);

				// if on the first item with SHIFT+TAB go to the last item
				$firstFocusable.off('keydown.accessibleTabInsideContainer').on('keydown.accessibleTabInsideContainer', function(event) {
					if (event.shiftKey && event.keyCode === KEYCODE.TAB) {
						event.preventDefault();
						$lastFocusable.get(0).focus();
					}
				});

				// if on the last item and TAB go to the first item
				$lastFocusable.off('keydown.accessibleTabInsideContainer').on('keydown.accessibleTabInsideContainer', function(event) {
					if (!event.shiftKey && event.keyCode === KEYCODE.TAB) {
						event.preventDefault();
						$firstFocusable.get(0).focus();
					}
				});

				if (tabToContainer) {
					if (_$container.is(':focusable')) {
						_$container.get(0).focus();
					} else if ($firstFocusable.length > 0) {
						$firstFocusable.get(0).focus();
					} else {
						_$container.attr('tabindex', '-1').get(0).focus();
					}
				}

				_isSet = true;
			};

			/**
			 * Unsets the `keydown` event listeners on the first and last tabbable element
			 * in the container
			 *
			 * @memberof DD.a11y.tabInsideContainer
			 */
			unset = function() {
				if (!_isSet) {
					console.warn('DD.a11y: tabInsideContainer hasn\'t been set yet.');
					return;
				}

				var $firstFocusable = _$container.find(':tabbable:first').eq(0),
					$lastFocusable = _$container.find(':tabbable:last').eq(0);

				$firstFocusable.off('keydown.accessibleTabInsideContainer');
				$lastFocusable.off('keydown.accessibleTabInsideContainer');

				_$container = null;
				_isSet = false;
			};

			return {
				set: set,
				unset: unset
			};
		}());

		ariaHideOthers = (function() {
			var _elements = [],
				_validateElements,
				_checkIfShouldBeHidden,
				set,
				unset;

			_validateElements = function(elements) {
				var validated = [];

				for (var i = 0, len = elements.length; i < len; i += 1) {
					var el = $(elements[i]).get(0),
						ignoreBlock = false;

					if (el.nodeName.toLowerCase() === 'script' || el.nodeName.toLowerCase() === 'link') {
						ignoreBlock = true;
					}

					if ($(el).attr('aria-hidden') === 'true') {
						ignoreBlock = true;
					}

					if (!ignoreBlock) {
						validated.push(el);
					}
				}

				return validated;
			};

			_checkIfShouldBeHidden = function(container, scope) {
				$(scope).find('> *').each(function(i, el) {
					if ($(el).get(0) === $(container).get(0)) {
						_elements = _elements.concat(_validateElements($(el).siblings()));
					} else if ($(el).find(container).length > 0) {
						_elements = _elements.concat(_validateElements($(el).siblings()));
						_checkIfShouldBeHidden(container, el);
					}
				});
			};

			set = function(container, scope) {
				unset();

				scope = scope || 'body';

				_elements = [];
				_checkIfShouldBeHidden(container, scope);

				$(_elements).attr({
					'aria-hidden': true
				});
			};

			unset = function() {
				if (_elements && _elements.length > 0) {
					$(_elements).removeAttr('aria-hidden');
					_elements = [];
				}
			};

			return {
				set: set,
				unset: unset
			};
		}());

		return {
			onEscape: onEscape,
			onClickOutside: onClickOutside,
			tabInsideContainer: tabInsideContainer,
			ariaHideOthers: ariaHideOthers
		};
	}());

}(jQuery, window, document));
