/* =============================================================================
   DD SHADE - A jQuery plugin for a blackout shade over content
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/shade.html */
;(function($, window, document, undefined) {

	'use strict';

	var	_isActive = false,
		_isAnimating = false,
		_$shade,
		_options,
		_triggerClick,
		_setClass,
		_init;

	/**
	 * Add or remove a class to the shade
	 *
	 * @memberof $.ddShade
	 * @param  {Object} event jQuery click event
	 * @private
	 */
	_triggerClick = function(event) {
		event.preventDefault();
		$(window).trigger('clicked.ddShade');
	};

	/**
	 * Add or remove a class to the shade
	 *
	 * @memberof $.ddShade
	 * @param  {Boolean} state Boolean to determine if to add or remove the class required
	 * @param  {String} className The class to add/remove
	 * @return {Boolean} Returns that state of the selected option as a boolean
	 * @private
	 */
	_setClass = function(state, className) {
		state = (typeof (state) === 'boolean' && state === true);

		if (state) {
			_$shade.addClass(className);
		} else {
			_$shade.removeClass(className);
		}

		return state;
	};

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddShade
	 * @param {Object} container The container for the shade to be positioned in
	 * @param {Object} options The options passed to the plugin
	 * @private
	 * */
	_init = function(container, options) {
		var $container = $(container);

		// don't run more than once
		if (typeof ($container.data('ddShade-isInit')) === 'boolean' && $container.data('ddShade-isInit') === true) {
			return;
		}

		// save the options globally to be reused in other functions
		_options = options;

		// create the shade DIV if it's not already created.
		if (document.getElementsByClassName(_options.classes.shade).length === 0) {
			$container.append($('<div/>', {
				class: _options.classes.shade,
				html: '&nbsp;'
			}));
		}

		_$shade = $(document.getElementsByClassName(_options.classes.shade));

		$container.data('ddShade-isInit', true);
	};

	$.extend({
		ddShade: {
			defaults: {
				classes: {
					shade: 'shade-bg',
					isActive: 'is-active',
					isBehindHeader: 'is-behind-header'
				}
			},

			/**
			 * Set the shade to go behind the fixed header rather than the default of in front
			 *
			 * @memberof $.ddShade
			 * @param  {Boolean} isBehindHeader Boolean to determine if to add or remove the class required
			 */
			setBehindHeader: function(setToBehind) {
				_setClass(setToBehind, _options.classes.isBehindHeader);
			},

			/**
			 * Set the shade to active (this is required before you set the opacity of it)
			 *
			 * @memberof $.ddShade
			 * @param  {Boolean} isActive Boolean to determine if to activate or deactivate
			 */
			setActive: function(setToState) {
				_setClass(setToState, _options.classes.isActive);
				_isActive = setToState;

				if (_isActive) {
					// .one will unbind the event immediately after it's triggered
					_$shade.one('click.ddShade', _triggerClick);
				}
			},

			/**
			 * Check if the shade is active
			 *
			 * @memberof $.ddShade
			 * @return {Boolean} True if the shade is currently active, False if inactive
			 */
			isActive: function() {
				return _isActive;
			},

			/**
			 * Check if the shade is animating
			 *
			 * @memberof $.ddShade
			 * @return {Boolean} True if the shade is currently animating, False if not
			 */
			isAnimating: function() {
				return _isAnimating;
			},

			/**
			 * Set the opacity of the shade, can be over a duration or immediately
			 *
			 * @memberof $.ddShade
			 * @param {Number} opacity Opacity to go to between 0 and 1. Is converted to a fixed 2 decimal places
			 * @param {Number} duration Duration in milliseconds over which the opacity should be set, use 0 for immediate
			 * @param {Boolean} [setToCurrent=false] Use true to set the opacity to it's current opacity prior to starting the animation. Used to avoid some display issues in IE
			 * @param {Function} [callback=null] Callback function to be called after complete
			 */
			opacity: function(opacity, duration, setToCurrent, callback) {
				var opacityTo = (typeof (opacity) === 'number') ? opacity.toFixed(2) : 1,
					opacityDuration = duration || 0,
					setCurrentOpacity = (setToCurrent === true) || false;

				if (setCurrentOpacity) {
					_$shade.css({
						opacity: _$shade.css('opacity')
					});
				}

				if (opacityDuration === 0) {
					_$shade.velocity('stop');
					_$shade.css({
						opacity: opacityTo
					});

					if (typeof (callback) === 'function') {
						callback();
					}
					return;
				}

				_isAnimating = true;

				_$shade.velocity('stop').velocity({
					opacity: opacityTo
				}, {
					duration: opacityDuration,
					complete: function() {
						_isAnimating = false;

						if (typeof (callback) === 'function') {
							callback();
						}
					}
				});
			}
		}
	}).fn.extend({
		ddShade: function(options) {
			options = $.extend(true, {}, $.ddShade.defaults, options);

			return $(this).each(function(i, el) {
				_init(el, options);
			});
		}
	});
})(jQuery, window, document);
