/* =============================================================================
   DD MODALS - A jQuery plugin for making accessible modal windows
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/responsive-table.html */
;(function($, window, document, undefined) {

	'use strict';

	var	_dynamicModalTypes = [],
		_itemThatOpenedTheCurrentModal = null,
		_isOpen = false,
		_debounceTimeout = null,
		$currentModal = null,
		$container,
		_options,
		_checkIfReady,
		_checkIfModalExists,
		_addEventsToModal,
		_removeEventsFromModal,
		_positionCurrentModal,
		_debouncePositionCurrentModal,
		_animateIn,
		_animateOut,
		_closeModal,
		_openModal,
		_dynamicModals,
		_init;

	/**
	 * Check if the modal has be initialised
	 *
	 * @memberof $.ddModals
	 * @private
	 * */
	_checkIfReady = function() {
		if ($container) {
			if ($container.data('ddModals-isInit') === true) {
				return true;
			}
		}

		console.warn('$.ddModals: Please ensure that you\'ve initialised the plugin first.');

		return false;
	};

	/**
	 * Checks if the modal exists on the page
	 *
	 * @memberof $.ddModals
	 * @private
	 * */
	_checkIfModalExists = function(id) {
		return ($container.find(document.getElementById(id)).length > 0);
	};

	/**
	 * Positions the current modal to the center of the screen
	 *
	 * @memberof $.ddModals
	 * @private
	 * */
	_positionCurrentModal = function() {
		if ($currentModal !== null) {
			var $modalContent = $currentModal.find('.' + _options.classes.modalContent),
				$modalContentOverflow = $modalContent.find('.' + _options.classes.modalContentOverflow);

			DD.noScroll.refresh();

			$modalContent.css({
				'max-height': ''
			});

			$modalContentOverflow.css({
				'max-height': '',
				overflow: ''
			});

			// assumes that the body is fixed position and offset
			// to stop background scrolling
			var top = -parseInt($('body').css('top'), 10);

			$currentModal.css({
				top: $(window).scrollTop() + top,
				height: $(window).height()
			});

			var modalContainerHeight = $currentModal.height(),
				modalHeight = $modalContent.height();

			if (modalHeight > modalContainerHeight) {
				$modalContent.css({
					'max-height': modalContainerHeight,
					top: 0
				});

				$modalContentOverflow.css({
					'max-height': modalContainerHeight,
					overflow: 'auto'
				});
			} else {
				$modalContent.css({
					top: (modalContainerHeight - modalHeight) / 2
				});
			}
		}
	};

	/**
	 * Position the modal after a slight timeout (100ms) to stop over thrashing the layout
	 *
	 * @memberof $.ddModals
	 * @private
	 * */
	_debouncePositionCurrentModal = function() {
		clearTimeout(_debounceTimeout);
		_debounceTimeout = setTimeout(_positionCurrentModal, 100);
	};

	/**
	 * Animate the current modal in
	 *
	 * @memberof $.ddModals
	 * @param {Function} callback Callback function to call after the modal is finished animating
	 * @private
	 * */
	_animateIn = function(callback) {
		$currentModal.trigger('modalAnimatingIn.ddModals');

		var scrollY = $(window).scrollTop();

		if (scrollY < 0) {
			scrollY = 0;
		}

		$currentModal.css({
			top: scrollY + 'px'
		});

		DD.noScroll.set();

		_options.animations.modal.reset($currentModal);

		// stop current animations
		$container.velocity('stop');
		$currentModal.velocity('stop');

		_options.animations.container.preShow($container, _options, function() {
			$container.addClass(_options.classes.isActive);

			_options.animations.container.show($container, _options, function() {
				_options.animations.modal.preShow($currentModal, _options, function() {
					$currentModal.addClass(_options.classes.isActive);

					_positionCurrentModal();

					$(window).on('resize.ddModals', _debouncePositionCurrentModal);
					$(window).on('scroll.ddModals', _debouncePositionCurrentModal);

					_options.animations.modal.show($currentModal, _options, function() {
						_isOpen = true;
						_addEventsToModal();
						$currentModal.trigger('modalOpened.ddModals');

						if (typeof (callback) === 'function') {
							return callback();
						}
					});
				});
			});
		});
	};

	/**
	 * Animate the current modal out
	 *
	 * @memberof $.ddModals
	 * @param {Function} callback Callback function to call after the modal is finished animating
	 * @private
	 * */
	_animateOut = function(callback) {
		if (!_isOpen) {
			return;
		}

		$currentModal.trigger('modalAnimatingOut.ddModals');
		$(window).off('resize.modal', _debouncePositionCurrentModal);
		$(window).off('scroll.modal', _debouncePositionCurrentModal);

		// stop current animations
		$container.velocity('stop');
		$currentModal.velocity('stop');

		_options.animations.modal.hide($currentModal, _options, function() {
			$currentModal.removeClass(_options.classes.isActive);

			if ($currentModal.find('.' + _options.classes.modalContent).hasClass(_options.classes.modalVideo)) {
				$currentModal.html('');
			}

			var $lastOpenModal = $currentModal;
			$currentModal = null;

			_options.animations.container.hide($container, _options, function() {
				_isOpen = false;
				$container.removeClass(_options.classes.isActive);
				$lastOpenModal.trigger('modalClosed.ddModals');

				DD.noScroll.unset();

				if (typeof (callback) === 'function') {
					return callback();
				}
			});
		});
	};

	/**
	 * Add events like locking the tabbing inside the modal,
	 * clicking on the outside of the modal to close
	 * and binding the ESC key to closing the modal.
	 * Requires DD.a11y.js
	 *
	 * @memberof $.ddModals
	 * @private
	 * */
	_addEventsToModal = function() {
		DD.a11y.tabInsideContainer.set($currentModal, true);

		if (!$currentModal.find('.' + _options.classes.modalContent).hasClass(_options.triggers.unskippable)) {
			DD.a11y.onClickOutside.set($currentModal.find('.' + _options.classes.modalContentOverflow), _closeModal);
			DD.a11y.onEscape.set(_closeModal);
		}

		// Set all other DOM elements to be aria hidden
		DD.a11y.ariaHideOthers.set($currentModal);

		//resize event
		if (_options.repositionModalOnEvent !== '') {
			$currentModal.on(_options.repositionModalOnEvent, _positionCurrentModal);
		}

		// focus on the first element
		if ($currentModal.find(_options.triggers.dialogStart).length > 0) {
			$currentModal.find(_options.triggers.dialogStart).focus();
		} else {
			$currentModal.focus();
		}
	};

	/**
	 * Remove the events from the current modal
	 * Requires DD.a11y.js
	 *
	 * @memberof $.ddModals
	 * @private
	 * */
	_removeEventsFromModal = function() {
		DD.a11y.tabInsideContainer.unset();

		if (!$currentModal.find('.' + _options.classes.modalContent).hasClass(_options.triggers.unskippable)) {
			DD.a11y.onClickOutside.unset();
			DD.a11y.onEscape.unset();
		}

		// Unset all other DOM elements from being aria hidden
		DD.a11y.ariaHideOthers.unset();

		//resize event
		if (_options.repositionModalOnEvent !== '') {
			$currentModal.off(_options.repositionModalOnEvent, _positionCurrentModal);
		}
	};

	/**
	 * Open a modal
	 *
	 * @memberof $.ddModals
	 * @param {String} id ID of the modal to open
	 * @param {Object} opener Button used to open the modal
	 * @param {Function} callback Callback function to call after the modal is opened
	 * @private
	 * */
	_openModal = function(id, opener, callback) {
		if (_checkIfReady()) {
			// if the opener is a jQuery object and isn't inside another modal
			if (typeof (opener) === 'object' && $(opener).closest($container).length === 0) {
				_itemThatOpenedTheCurrentModal = $(opener);
			}

			//check if already open
			if (_isOpen) {
				//if is self, don't reopen, else swap out
				if ($currentModal.attr('id') === id) {
					return true;
				} else {
					return _closeModal(function() {
						_openModal(id, callback);
					});
				}
			} else {
				if (_checkIfModalExists(id)) {
					_isOpen = true;

					// close on background click
					$currentModal = $(document.getElementById(id));
					_animateIn(callback);

					if (typeof (_options.beforeModalOpen) === 'function') {
						_options.beforeModalOpen();
					}

					return true;
				}
			}
		}

		return false;
	};

	/**
	 * Close the currently open modal
	 *
	 * @memberof $.ddModals
	 * @param {Function} callback Callback function to call after the modal is closed
	 * @private
	 * */
	_closeModal = function(callback) {
		if (_checkIfReady()) {
			_removeEventsFromModal();

			_animateOut(function() {
				if (typeof (callback) === 'function') {
					// if there is a callback we're probably opening another modal
					callback();
				} else {
					// if closing entirely, focus on original element that
					// opened the modal in the first place
					if (_itemThatOpenedTheCurrentModal) {
						$(_itemThatOpenedTheCurrentModal).focus();
						_itemThatOpenedTheCurrentModal = null;
					}
				}
			});
		}
	};

	/**
	 * Dynamic modals
	 *
	 * @memberof $.ddModals
	 */
	_dynamicModals = (function() {
		var _modalMediaQueries = {},
			_bindFormToOriginal,
			_addNewModal,
			_addAtMediaQuery,
			addNewModalFromTemplate,
			init;

		/**
		 * Dynamically add a modal after page load based off a source element
		 *
		 * @memberof $.ddModals
		 * @param {Object} $original Original form element
		 * @param {Object} $modal Form elements contained inside the modal
		 * @private
		 * */
		_bindFormToOriginal = function($original, $modal) {
			// map form elements to each other so only the original form is used
			$modal.find('input:not(:submit), select').each(function(i, el) {
				var $el = $(el),
					originalId = $el.attr('id'),
					originalName = $el.attr('name'),
					$originalEl = $original.find('#' + originalId);

				// update element attribute
				$el.attr({
					id: originalId + _options.dynamicModalFormSuffix
				});

				// update the element's label
				$('label[for="' + originalId + '"]').attr('for', originalId + _options.dynamicModalFormSuffix);

				// bind update events to update the main form
				$el.on('keyup.ddModalForms change.ddModalForms', function() {
					$originalEl.val($(this).val());
				});
			});

			// map submit buttons to each other so only the original submit button is used.
			$modal.find('button, input[type="submit"]').each(function(i, el) {
				var $el = $(el);

				$el.on('click.ddModalForms', function(event) {
					event.preventDefault();

					// close modal first
					_closeModal(function() {
						// fire the proper click event for the main form instead
						$original.find('button, input[type="submit"]').eq(i).trigger('click', [true]);
					});
				});
			});
		};

		/**
		 * Dynamically add a modal after page load based off a source element
		 *
		 * @memberof $.ddModals
		 * @param {String} id Id to be used by the new modal
		 * @param {String} type Additional class applied to the modal to help with custom styling
		 * @param {Object} $elem jQuery object of the DOM element to duplicate
		 * @param {Function} callback Callback function to call when the modal is created
		 * @private
		 * */
		_addNewModal = function(id, type, $elem, callback) {
			if (_checkIfModalExists(id)) {
				// remove old one
				$(document.getElementById(id)).remove();
			}

			$container.prepend(_options.templates.container(id, type, _options));

			var $modal = $(document.getElementById(id));

			$modal.find('.' + _options.classes.modalContentOverflow).html($elem.clone());

			_bindFormToOriginal($elem, $modal.find('.' + _options.classes.modalContentOverflow));

			if (typeof (callback) === 'function') {
				callback($modal);
			}
		};

		/**
		 * Dynamically add a modal after page load at a certain media query size
		 *
		 * @memberof $.ddModals
		 * @param {String} mq DD.bp media query string
		 * @param {String} id Id to be used by the new modal
		 * @param {String} type Additional class applied to the modal to help with custom styling
		 * @param {Object} $elem jQuery object of the DOM element to duplicate
		 * @param {Function} callback Callback function to call when the modal is created
		 * @private
		 * */
		_addAtMediaQuery = function(mq, id, type, $elem, callback) {
			if ($elem.length === 0) {
				return; //element doesn't exist - don't continue
			}

			// loops through all the modals at wach media query and adds them to the page
			var createModal = function() {
				for (var mq in _modalMediaQueries) {
					if (_modalMediaQueries.hasOwnProperty(mq)) {
						var mqHandler = _modalMediaQueries[mq].handler,
							mqModals = _modalMediaQueries[mq].modals;

						while (mqModals.length > 0) {
							var modal = mqModals.pop();
							_addNewModal(modal.id, modal.type, modal.$elem, modal.callback);
						}

						enquire.unregister(DD.bp.get(mq), mqHandler);

						delete _modalMediaQueries[mq];
					}
				}
			};

			if (mq === false || DD.bp.is(mq)) {
				_addNewModal(id, type, $elem, callback);
			} else {
				// only setup the enquire listener once per media query
				// enquire can't unregister multiple listeners that reference the same handler
				// so it's much safer to do it this way if you have multiple modals on the same mq
				if (typeof (_modalMediaQueries[mq]) !== 'object') {
					_modalMediaQueries[mq] = {
						handler: {
							match: createModal
						},
						modals: []
					};
					enquire.register(DD.bp.get(mq), _modalMediaQueries[mq].handler);
				}

				if (_modalMediaQueries[mq] && typeof (_modalMediaQueries[mq].modals) === 'object') {
					_modalMediaQueries[mq].modals.push({
						id: id,
						type: type,
						$elem: $elem,
						callback: callback
					});
				}
			}
		};

		/**
		 * Dynamically add a modal after page load based off a source element
		 *
		 * @memberof $.ddModals
		 * @param {String} id Id to be used by the new modal
		 * @param {String} type Additional class applied to the modal to help with custom styling
		 * @param {Object} template jquery element of templated items to be displayed
		 * @param {Function} callback Callback function to call when the modal is created
		 * */
		addNewModalFromTemplate = function(id, type, template, callback) {
			if (_checkIfModalExists(id)) {
				// remove old one
				$(document.getElementById(id)).remove();
			}

			$container.prepend(_options.templates.container(id, type, _options));

			var $modal = $(document.getElementById(id));

			$modal.find('.' + _options.classes.modalContentOverflow).html(template);

			if (typeof (callback) === 'function') {
				callback($modal);
			}
		};

		/**
		 * Run through the HTML of the page and create dynamic modals based on the
		 * page structure
		 *
		 * @memberof $.ddModals
		 * @private
		 * */
		init = function() {
			// dynamic modals
			$('.' + _options.triggers.dynamicModal).each(function(i, el) {
				var $dynamicContainer = $(el),
					id = $dynamicContainer.attr(_options.attrs.modalId),
					config = $.ddModals.dynamicModal.getConfig(id);

				if (config !== false) {
					_addAtMediaQuery(config.mq, id, config.type, $dynamicContainer, config.callback);
				}
			});
		};

		return {
			addNewModalFromTemplate: addNewModalFromTemplate,
			init: init
		};
	})();

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddModals
	 * @param {Object} container The modal container
	 * @param {Object} options The options passed to the plugin
	 * @private
	 * */
	_init = function(container) {
		$container = $(container);

		// don't run more than once
		if (typeof ($container.data('ddModals-isInit')) === 'boolean' && $container.data('ddModals-isInit') === true) {
			return;
		}

		//check if there is currently a modal active
		if ($container.hasClass(_options.classes.isActive)) {
			//if there is no active modal inside, the container shouldn't be active
			if ($container.find('.' + _options.classes.modal + '.' + _options.classes.isActive).length > 0) {
				_isOpen = true;
				$currentModal = $container.find('.' + _options.classes.modal + '.' + _options.classes.isActive);
				_addEventsToModal();

				DD.noScroll.set();
			} else {
				$container.removeClass(_options.classes.isActive);
				_isOpen = false;
			}
		} else {
			// shouldn't have an internal item without the container being active
			$container.find('.' + _options.classes.modal + '.' + _options.classes.isActive).removeClass(_options.classes.isActive);
		}

		$container.on('click.ddModals', '.' + _options.triggers.closeModal, function(event) {
			event.preventDefault();
			_closeModal();
		});

		$(document).on('click.ddModals', '.' + _options.triggers.openModal, function(event) {
			event.preventDefault();

			var $button = $(this),
				id = $button.attr(_options.attrs.modalId);

			_openModal(id, $button);
		});

		$(document).on('click.ddModals', '.' + _options.triggers.modalVideo, function(event) {
			event.preventDefault();

			var $button = $(this),
				videoId = $button.attr(_options.attrs.modalVideoId),
				videoOptions = $button.attr(_options.attrs.modalVideoOptions),
				videoType = $button.attr(_options.attrs.modalVideoType) || 'youtube',
				template = _options.templates.videoPlayer(videoId, videoOptions, videoType);

			_dynamicModals.addNewModalFromTemplate('modal-video', _options.classes.modalVideo, template, function() {
				_openModal('modal-video', $button);
			});
		});

		_dynamicModals.init();

		// the table has been initialised - this will ensure that it can't be run multiple times
		$container.data('ddModals-isInit', true);
	};

	$.extend({
		ddModals: {
			defaults: {
				dynamicModalFormSuffix: '-modal',
				repositionModalOnEvent: '',
				beforeModalOpen: false,
				attrs: {
					modalId: 'data-modal-id',
					modalVideoId: 'data-modal-video-id',
					modalVideoOptions: 'data-modal-video-options',
					modalVideoType: 'data-modal-video-type'
				},
				durations: {
					container: {
						show: 250,
						hide: 250
					},
					modal: {
						show: 250,
						hide: 250,
						reposition: 150
					}
				},
				triggers: {
					dialogStart: 'js-modal-dialog-start',
					openModal: 'js-modal-open',
					closeModal: 'js-modal-close',
					dynamicModal: 'js-modal-dynamic',
					modalVideo: 'js-modal-video',
					unskippable: 'js-modal-unskippable'
				},
				classes: {
					modal: 'modal',
					modalContent: 'modal-content',
					modalContentOverflow: 'modal-content-overflow',
					modalVideo: 'modal-video',
					modalClose: 'modal-close',
					isActive: 'is-active'
				},
				templates: {
					container: function(id, type, options) {
						var $modalContainer,
							$modalContent,
							$modalStart,
							$modalClose;

						$modalContainer = $('<div />', {
							id: id,
							tabindex: '-1',
							class: options.classes.modal,
							html: $('<div/>', {
								class: 'l-padding'
							})
						});

						$modalContent = $('<div />', {
							class: options.classes.modalContent + ' ' + type,
							html: $('<div/>', {
								class: options.classes.modalContentOverflow
							})
						});

						$modalStart = $('<span />', {
							class: options.triggers.dialogStart + ' vh',
							tabindex: '-1',
							html: 'Dialog Start. Use the ESC key to close, or press the close button.'
						});

						$modalClose = $('<button />', {
							role: 'button',
							class: options.classes.modalClose + ' ' + options.triggers.closeModal,
							html: 'Close<span class="vh"> Dialog, Dialog End</span>'
						});

						$modalContent.prepend($modalStart).append($modalClose);
						$modalContainer.find('.l-padding').append($modalContent);

						return $modalContainer;
					},
					videoPlayer: function(videoId, options, videoType) {
						var type = videoType || 'youtube',
							$intrinsic,
							$player,
							videoSource;

						$intrinsic = $('<div />', {
							class: 'intrinsic',
							html: $('<div />', {
								class: 'intrinsic-wrap intrinsic-16x9'
							})
						});

						if (type === 'youtube') {
							videoSource = 'http://www.youtube.com/embed/' + videoId + options;
						}

						$player = $('<iframe />', {
							class: 'intrinsic-el',
							src: videoSource,
							attr: {
								allowfullscreen: true,
								frameborder: '0'
							}
						});

						$intrinsic.find('.intrinsic-wrap').append($player);

						return $intrinsic;
					}
				},
				animations: {
					container: {
						preShow: function($container, options, callback) {
							$container.css({
								opacity: 0
							});

							callback();
						},
						show: function($container, options, callback) {
							$container.velocity({
								opacity: 1,
								translateZ: 0
							}, {
								duration: options.durations.container.show,
								complete: callback
							});
						},
						hide: function($container, options, callback) {
							$container.velocity({
								opacity: 0,
								translateZ: 0
							}, {
								duration: options.durations.container.hide,
								complete: callback
							});
						}
					},
					modal: {
						reset: function($currentModal) {
							$currentModal.css({
								opacity: 0
							});
						},
						preShow: function($currentModal, options, callback) {
							$currentModal.removeAttr('style');
							$currentModal.css({
								opacity: 0,
								scale: 0.9,
								translateZ: 0
							});

							callback();
						},
						show: function($currentModal, options, callback) {
							$currentModal.velocity({
								opacity: 1,
								scale: 1,
								translateZ: 0
							}, {
								duration: options.durations.modal.show,
								complete: function() {
									$currentModal.find('.' + options.classes.modalContent).css({
										transition: options.durations.modal.reposition + 'ms top'
									});

									callback();
								}
							});
						},
						hide: function($currentModal, options, callback) {
							$currentModal.velocity({
								opacity: 0,
								scale: 0.9,
								translateZ: 0
							}, {
								duration: options.durations.modal.hide,
								complete: function() {
									$currentModal.removeAttr('style');
									$currentModal.find('.' + options.classes.modalContent).removeAttr('style');

									callback();
								}
							});
						}
					}
				}
			},

			dynamicModal: {
				defaults: {
					mq: false,
					type: 'modal-type-dynamic',
					callback: function() {}
				},
				getConfig: function(id) {
					var config = {},
						matchedConfig;

					if (id === null) {
						return $.ddModals.dynamicModal.defaults;
					}

					for (var key in _dynamicModalTypes) {
						if (_dynamicModalTypes.hasOwnProperty(key) && key === id) {
							matchedConfig = _dynamicModalTypes[key];
						}
					}

					// fill out any unfilled options with the defaults
					$.extend(config, $.ddModals.dynamicModal.defaults, matchedConfig);

					if (config) {
						return config;
					}

					console.warn('$.ddModals: Dynamic modal of id "' + id + '" not found. Using defaults.');

					return $.ddModals.dynamicModal.defaults;
				},
				addType: function(id, config) {
					_dynamicModalTypes[id] = config;
				}
			},

			open: _openModal,

			close: _closeModal,

			isOpen: function() {
				return _isOpen;
			}
		}
	}).fn.extend({
		ddModals: function(options) {
			_options = $.extend(true, {}, $.ddModals.defaults, options);

			if (window.DD && !window.DD.a11y) {
				console.error('$.ddModals: Please ensure that dd.a11y.js is included in your project.');
				return;
			}

			if (window.DD && !window.DD.noScroll) {
				console.error('$.ddModals: Please ensure that dd.noScroll.js is included in your project.');
				return;
			}

			return $(this).each(function(i, el) {
				_init(el);
			});
		}
	});
})(jQuery, window, document);
