/* =============================================================================
   DD TOOLTIP - A jQuery plugin for accessible tooltips
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/tooltip.html */
;(function($, window, document, undefined) {

	'use strict';

	var	_init;

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddTooltip
	 * @param {Object} button The original button to be enhanced
	 * @param {Object} options The options passed to the plugin
	 * @param {Number} i Index of the item on the page to help generate unique ids
	 * @private
	 * */
	_init = function(button, options, i) {
		var $button = $(button),
			content = $button.attr(options.attrs.text) || '',
			htmlContent = $button.attr(options.attrs.html) || '',
			id = options.tooltipIdPrefix + i,
			_isClicked = false,
			_isDisplayed = false,
			$container,
			$tooltip,
			_setWidth,
			_setPosition,
			_showTooltip,
			_hideTooltip,
			_hideOnClickOutside,
			_repositionOnResize,
			_onClick,
			_onOver,
			_onOut;

		// don't run more than once
		if (typeof ($button.data('ddTooltip-isInit')) === 'boolean' && $button.data('ddTooltip-isInit') === true) {
			return;
		}

		// keep the existing button
		$button.attr({
			role: 'button',
			type: 'button',
			'aria-controls': id
		}).removeAttr(options.attrs.text).removeAttr(options.attrs.html);

		// Disable if the content is supplied
		if ((!options.allowHTMLContent && content === '') || (options.allowHTMLContent && content === '' && htmlContent === '')) {
			console.warn('$.ddTooltip: No content supplied for tooltip. Ensure that tooltip content is supplied in the ' + options.attrs.text + ' attribute');
			return;
		}

		// generate a wrapper around the tooltip
		$container = $('<span/>', {
			class: options.classes.container
		});

		$button.wrap($container);

		// redefine the container in its new position
		$container = $button.closest('.' + options.classes.container);

		var $contentForTooltip;

		if (htmlContent !== '' && options.allowHTMLContent) {
			$contentForTooltip = htmlContent;
		} else {
			$contentForTooltip = $('<p/>', {
				html: content
			});
		}

		// create the tooltip content
		$tooltip = $('<div/>', {
			id: id,
			class: options.classes.tooltip,
			role: 'tooltip',
			attr: {
				'aria-hidden': true
			},
			html: $('<div/>', {
				class: options.classes.content,
				html: $contentForTooltip
			})
		});

		// insert the tooltip after the button (which is now inside the container)
		$button.after($tooltip);

		// set the width of the tooltip - used to ensure that weird widths aren't experienced
		_setWidth = function() {
			var tooltipWidth;

			$tooltip.css({
				position: 'static',
				display: 'inline-block',
				width: ''
			}).addClass(options.classes.isActive);

			tooltipWidth = $tooltip.width();

			tooltipWidth = (tooltipWidth < options.minWidth) ? options.minWidth : tooltipWidth;
			tooltipWidth = parseInt(tooltipWidth / 2, 10) * 2; //resolve rounding issues

			$tooltip.removeClass(options.classes.isActive).css({
				position: '',
				display: ''
			}).width(tooltipWidth);
		};

		// set the position of the tooltip
		_setPosition = function() {
			var $tooltipContent = $tooltip.find('.' + options.classes.content),
				buttonWidth,
				buttonHeight,
				tooltipWidth,
				tooltipHeight,
				tooltipBaseLeft,
				tooltipBaseTop,
				viewportTop,
				viewportBottom,
				viewportLeft,
				viewportRight,
				tooltipTop,
				tooltipBottom,
				tooltipLeft,
				tooltipRight,
				maxOffset;

			// temporarily show the tooltip to get accurate positions and calcs
			if (!_isDisplayed) {
				$tooltip.addClass(options.classes.isActive);
			}

			// reset the content position
			$tooltipContent.css({
				left: '',
				right: ''
			});

			// get the base widths of the tooltip and tooltip button
			buttonWidth = $button.width();
			buttonHeight = $button.height();
			tooltipWidth = $tooltip.width();
			tooltipHeight = $tooltip.height();

			// position the tooltip center top of the button
			tooltipBaseLeft = parseInt(-(tooltipWidth / 2) + options.offsets.left + (buttonWidth / 2), 10);
			tooltipBaseTop = parseInt(-tooltipHeight + options.offsets.top, 10);

			// set the tooltip to the default position
			$tooltip.css({
				left: tooltipBaseLeft,
				top: tooltipBaseTop
			});

			// get viewport sizes and pos
			viewportTop = $(window).scrollTop();
			viewportBottom = viewportTop + $(window).height();
			viewportLeft = $(window).scrollLeft();
			viewportRight = viewportLeft + $(window).width();

			// get the current tooltip sizes and pos
			tooltipTop = $tooltip.offset().top;
			tooltipBottom = tooltipTop + $tooltip.height();
			tooltipLeft = $tooltip.offset().left;
			tooltipRight = tooltipLeft + $tooltip.width();

			// top/bottom alignment check
			if (tooltipTop >= viewportTop && tooltipBottom <= viewportBottom) {
				// by default the tooltip is positioned to the top - so remove the bottom class
				$tooltip.removeClass(options.classes.isBelow);
			} else {
				$tooltip.addClass(options.classes.isBelow).css({
					top: buttonHeight + (options.offsets.top * -1)
				});
			}

			// check if the tooltip is going to be cut off sideways
			maxOffset = parseInt((tooltipWidth / 2) - (options.offsets.sides * 2), 10);

			if (tooltipLeft >= viewportLeft && tooltipRight <= viewportRight) {
				// tooltip is centered as expected
				$tooltipContent.css({
					left: '',
					right: ''
				});
			} else if (tooltipLeft <= viewportLeft) {
				// tooltip is too far left
				var offsetLeft = parseInt((tooltipLeft + viewportLeft) * -1 + options.offsets.sides, 10);
				offsetLeft = (offsetLeft > maxOffset) ? maxOffset : offsetLeft;

				$tooltipContent.css({
					left: offsetLeft
				});
			} else if (tooltipRight >= viewportRight) {
				// tooltip is too far right
				var offsetRight = parseInt((tooltipRight - viewportRight) + options.offsets.sides, 10);
				offsetRight = (offsetRight > maxOffset) ? maxOffset : offsetRight;

				$tooltipContent.css({
					right: offsetRight
				});
			}

			// remove the active class if it shouldn't be visible
			if (!_isDisplayed) {
				$tooltip.removeClass(options.classes.isActive);
			}
		};

		// show the tooltip
		_showTooltip = function() {
			var afterShow = function() {};

			_setWidth();
			_setPosition();

			$tooltip.attr('aria-hidden', false);

			_isDisplayed = true;

			if (options.durations.show === 0) {
				$tooltip.addClass(options.classes.isActive);
				afterShow();
			} else {
				options.animations.show($tooltip, options, afterShow);
			}
		};

		// hide the tooltip
		_hideTooltip = function() {
			var afterHide = function() {
				if (_isClicked) {
					$(window).off('resize.ddTooltip', $.throttle(200, _repositionOnResize));
					$(document).off('click.ddTooltip', _hideOnClickOutside);

					_isClicked = false;
				}
			};

			$container.removeClass(options.classes.isActive);
			$tooltip.attr('aria-hidden', true);

			_isDisplayed = false;

			if (options.durations.hide === 0) {
				$tooltip.removeClass(options.classes.isActive);
				afterHide();
			} else {
				options.animations.hide($tooltip, options, afterHide);
			}
		};

		// hide the tooltip when clicked outside of it
		_hideOnClickOutside = function(event) {
			if ($(event.target).closest(document.getElementById(id)).length === 0) {
				_hideTooltip();
			}
		};

		// reposition the tooltip when open
		_repositionOnResize = function() {
			if (_isClicked && _isDisplayed) {
				_setPosition();
			}
		};

		// variation for on click so that it doesn't get hidden on blur
		_onClick = function(event) {
			event.preventDefault();

			if (_isClicked === false) {
				_isClicked = true;

				$container.addClass(options.classes.isActive);

				if (!_isDisplayed) {
					$container.trigger('show.ddTooltip');
				}

				$(window).on('resize.ddTooltip', $.throttle(200, _repositionOnResize));

				setTimeout(function() {
					$(document).on('click.ddTooltip', _hideOnClickOutside);
				}, 10);
			} else {
				$container.trigger('hide.ddTooltip');
			}
		};

		_onOver = function() {
			if (!_isClicked && !_isDisplayed) {
				_showTooltip();
			}
		};

		_onOut = function() {
			if (!_isClicked && _isDisplayed) {
				_hideTooltip();
			}
		};

		// make sure there is no double up of events
		$button.off('.ddTooltip');

		// for hover and focus events
		if (options.showOnHover || options.showOnFocus) {
			var overEvents = [],
				outEvents = [];

			if (options.showOnHover) {
				overEvents.push('mouseover.ddTooltip');
				outEvents.push('mouseout.ddTooltip');
			}

			if (options.showOnFocus) {
				overEvents.push('focus.ddTooltip');
				outEvents.push('blur.ddTooltip');
			}

			$button.on(overEvents.join(' '), _onOver)
				.on(outEvents.join(' '), _onOut);
		}

		// for click events
		if (options.showOnClick) {
			$button.on('click.ddTooltip', _onClick);
		}

		// for default container events which can be triggered externally
		$container.off('.ddTooltip')
			.on('show.ddTooltip', _showTooltip)
			.on('hide.ddTooltip', _hideTooltip);

		$button.data('ddTooltip-isInit', true);
	};

	$.extend({
		ddTooltip: {
			defaults: {
				minWidth: 176,
				tooltipIdPrefix: 'tooltip-',
				showOnHover: true,
				showOnFocus: true,
				showOnClick: true,
				allowHTMLContent: true,
				offsets: {
					left: 0,
					top: -10,
					sides: 10
				},
				attrs: {
					text: 'title',
					html: 'data-tooltip-html'
				},
				durations: {
					show: 200,
					hide: 200
				},
				classes: {
					container: 'tooltip-container',
					tooltip: 'tooltip',
					content: 'tooltip-content',
					visuallyhidden: 'vh',
					isActive: 'is-active',
					isBelow: 'is-below'
				},
				animations: {
					show: function($tooltip, options, callback) {
						$tooltip.velocity('stop').velocity({
							opacity: 0,
							translateY: ($tooltip.hasClass(options.classes.isBelow)) ? '-5px' : '5px'
						}, {
							duration: 0
						}).addClass(options.classes.isActive).velocity({
							opacity: 1,
							translateY: 0
						}, {
							duration: options.durations.show,
							complete: callback
						});
					},
					hide: function($tooltip, options, callback) {
						$tooltip.velocity('stop').velocity({
							opacity: 0,
							translateY: ($tooltip.hasClass(options.classes.isBelow)) ? '-5px' : '5px'
						}, {
							duration: options.durations.hide,
							complete: function() {
								$tooltip.removeClass(options.classes.isActive);
								callback();
							}
						});
					}
				}
			}
		}
	}).fn.extend({
		ddTooltip: function(options) {
			options = $.extend(true, {}, $.ddTooltip.defaults, options);

			return $(this).each(function(i, el) {
				_init(el, options, i);
			});
		}
	});
})(jQuery, window, document);
