/* =============================================================================
   DD TABS - A jQuery plugin to display accessible tabs
   ========================================================================== */

/* http://fed.donlineclients.com/demo/modules/plugins/tabs.html */
;(function($, window, document, undefined) {

	'use strict';

	var	_formatIdString,
		_overrideWithDataAttribute,
		_updateOptionsFromDOM,
		_setNavListItemToActive,
		_init;

	/**
	 * Formats the ID string to remove the # from the start
	 *
	 * @memberof $.ddTabs
	 * @param {String} id The ID string usually obtained from a href attribute of a link
	 * @private
	 * */
	_formatIdString = function(id) {
		if (id && id.indexOf('#') > -1) {
			return id.substring(id.indexOf('#') + 1, id.length);
		}

		return id;
	};

	/**
	 * Returns the override value if the data attribute exists on the element,
	 * otherwise returns the fallback value provided.
	 *
	 * @memberof $.ddTabs
	 * @param {Object} $el The jQuery element to check for the data attribute on
	 * @param {Object} attrName The name of the data attribute to check for
	 * @param {Object} attrName The value to return if the attribute isn't set (i.e. the value being potentially overriden)
	 * @private
	 * */
	_overrideWithDataAttribute = function($el, attrName, fallbackValue) {
		var strippedAttrName,
			value;

		// strip 'data-' from beginning of attribute name if it's there
		strippedAttrName = attrName.replace(/^data-/, '');
		value = $el.data(strippedAttrName);

		// Use the original value if there is no data attribute
		if (typeof value === 'undefined') {
			return fallbackValue;
		}

		// return the override value
		return value;
	}

	/**
	 * Look at the container to see if there are any DOM attributes that
	 * can override base options
	 *
	 * @memberof $.ddTabs
	 * @param {Object} $container The jQuery element to group
	 * @param {Object} options The options passed to the plugin
	 * @private
	 * */
	_updateOptionsFromDOM = function($container, options) {
		var updatedOptions = $.extend(true, {}, options);

		updatedOptions.updateHash = _overrideWithDataAttribute($container, options.attrs.updateHash, options.updateHash);
		updatedOptions.scroll = _overrideWithDataAttribute($container, options.attrs.scroll, options.scroll);
		updatedOptions.navFullWidth = _overrideWithDataAttribute($container, options.attrs.navFullWidth, options.navFullWidth);
		updatedOptions.at = _overrideWithDataAttribute($container, options.attrs.at, options.at);

		return updatedOptions;
	};

	/**
	 * Set the active state of the navigation list item
	 *
	 * @memberof $.ddTabs
	 * @param {Object} $navListItem The jQuery element of the navigation list item
	 * @private
	 * */
	_setNavListItemToActive = function($navListItem, options) {
		$navListItem.addClass(options.classes.isActive);
	};

	/**
	 * The initaliser for the module
	 *
	 * @memberof $.ddTabs
	 * @param {Object} $container The jQuery element to group
	 * @param {Object} options The options passed to the plugin
	 * @param {Number} i Index of the item on the page to help generate unique ids
	 * @private
	 * */
	_init = function(container, options, i) {
		var $container = $(container),
			tabsContainerIndex = i,
			$tabsContainer = $container.find('> .' + options.classes.tabs),
			_activeTab = null,
			_isDisabled = false,
			_isAnimating = false,
			$tabsList,
			$navigation,
			_tabLength,
			_activeIsSet,
			_firstActiveIndex,
			_aria,
			_hideActiveTab,
			_onTabChange,
			_onTabTrigger,
			_onAttach,
			_onDetach,
			_onDestroy,
			_initTabs;

		// don't run more than once
		if (typeof ($container.data('ddTabs-isInit')) === 'boolean' && $container.data('ddTabs-isInit') === true) {
			return;
		}

		// update the ec item options based on the DOM
		options = _updateOptionsFromDOM($container, options);

		// add container for the navigation items
		$container.prepend($('<div/>', {
			class: options.classes.navigation,
			html: $('<ul/>', {
				attr: {
					role: 'tablist'
				}
			})
		}));

		// store the navigation item
		$navigation = $container.find('> .' + options.classes.navigation + ' > ul');

		// get a list of tabs and groups
		$tabsList = $tabsContainer.find([
			'> .' + options.classes.tab,
			'> .' + options.classes.tabGroup,
			'> .' + options.classes.tabGroup + ' > .' + options.classes.tab
		].join(', '));

		// get the length of items
		_tabLength = $tabsList.length;

		if (options.navFullWidth) {
			$navigation.addClass(options.classes.hasItems.replace('#', _tabLength));
		}

		// check if a tab has already been set as active
		_activeIsSet = ($tabsContainer.find([
			'> .' + options.classes.tab + '.' + options.classes.isActive,
			'> .' + options.classes.tabGroup + ' > .' + options.classes.tab + '.' + options.classes.isActive
		].join(', ')).length > 0);

		// determine if we've got groups, if so the first active tab index is 1 not 0
		_firstActiveIndex = ($tabsList.eq(0).hasClass(options.classes.tabGroup)) ? 1 : 0;

		// aria actions
		_aria = {
			// add the aria attributes when needed
			add: function() {
				$navigation.find('li').each(function(i, el) {
					var $el = $(el),
						$tabButton = $el.find('button');

					$tabButton.attr({
						role: 'tab',
						'aria-controls': $tabButton.data('ddTabs-controls'),
						'aria-selected': $el.hasClass(options.classes.isActive)
					});
				});

				$tabsList.each(function(i, el) {
					var $tab = $(el);

					$tab.attr({
						role: 'tabpanel',
						'aria-labelledby': $tab.data('ddTabs-labelledby'),
						'aria-hidden': (!$tab.hasClass(options.classes.isActive)),
						tabindex: 0
					});
				});
			},
			// remove the aria attributes when not needed
			remove: function() {
				$tabsList.each(function(i, el) {
					var $tab = $(el);

					$tab.removeAttr('role')
						.removeAttr('aria-labelledby')
						.removeAttr('aria-hidden')
						.removeAttr('tabindex');
				});
			},
			// update the status of the tabs if it's not disabled
			update: function() {
				if (!_isDisabled) {
					$navigation.find('li').each(function(i, el) {
						var $el = $(el),
							$tabButton = $el.find('button');

						$tabButton.attr({
							'aria-selected': $el.hasClass(options.classes.isActive)
						});
					});

					$tabsList.each(function(i, el) {
						var $tab = $(el);

						$tab.attr({
							'aria-hidden': (!$tab.hasClass(options.classes.isActive))
						});
					});
				}
			}
		};

		// hide active tab (must be called before showing the new tab)
		_hideActiveTab = function(id, init, callback) {
			if (!_activeTab) {
				callback();
			} else {
				if (id !== _activeTab.id) {
					var afterHide = function() {
						$(_activeTab.$tab)
							.addClass(options.classes.isHidden)
							.removeClass(options.classes.isActive);

						callback();
					};

					$(_activeTab.$navListItem).removeClass(options.classes.isActive).find('a > .vh').remove();

					if (init) {
						afterHide();
					} else {
						if (_isDisabled) {
							afterHide();
							return;
						}

						$(_activeTab.$tab).velocity('stop').velocity({
							opacity: 0
						}, {
							duration: options.durations.hide,
							complete: afterHide
						});
					}
				} else {
					_isAnimating = false;
				}
			}
		};

		_onTabChange = function(event, id, init) {
			var tabFound = false,
				$nextActiveTab,
				$tabButton,
				$navListItem,
				afterShow,
				showTab;

			if (_isAnimating) {
				return;
			}

			// validate inputted variables
			id = _formatIdString(id);
			init = (typeof (init) === 'boolean') ? init : false;

			// check if the tab can be found
			$tabsList.each(function(i, el) {
				if (id === $(el).attr('id')) {
					$nextActiveTab = $(el);
					tabFound = true;
				}
			});

			// if the tab doesn't exist, no change can happen
			if (!tabFound) {
				return false;
			}

			// check if the link to the tab can be found
			$tabButton = $navigation.find('button[aria-controls="' + id + '"]');

			if ($tabButton.length === 0) {
				return false;
			}

			$navListItem = $tabButton.closest('li');

			// everything is found and works as expected
			_isAnimating = true;

			// function to call after show has completed
			afterShow = function() {
				$nextActiveTab.css({
					opacity: ''
				});

				_isAnimating = false;

				_activeTab = {
					id: id,
					$tab: $nextActiveTab,
					$navListItem: $navListItem
				};

				$nextActiveTab.addClass(options.classes.isActive);

				_aria.update();

				// trigger events so people can listen
				$container.trigger('tabChanged.ddTabs', [id, $nextActiveTab, $tabsList]);

				// set path
				if (options.updateHash) {
					window.location.hash = '#' + options.tabIdPrefix + id;
				}
			};

			showTab = function() {
				if (_isDisabled) {
					$nextActiveTab.css({ opacity: 1 }).removeClass(options.classes.isHidden);
					afterShow();

					return;
				}

				$nextActiveTab.removeClass(options.classes.isHidden).velocity('stop').velocity({
					opacity: 1
				}, {
					duration: options.durations.show,
					complete: afterShow
				});
			};

			_setNavListItemToActive($navListItem, options);

			if (init) {
				_hideActiveTab(id, init, function() {
					$nextActiveTab.css({ opacity: 1 }).removeClass(options.classes.isHidden);
					afterShow();
				});
			} else {
				_hideActiveTab(id, init, showTab);
			}

			if (options.scroll) {
				options.animations.scrollPage($container, options);
			}
		};

		_onTabTrigger = function(event) {
			event.preventDefault();

			var $triggerLink = $(this),
				id = _formatIdString($triggerLink.data('ddTabs-controls')),
				$tabButton = $('.' + options.classes.navigation).find('button[aria-controls="' + id + '"]');

			if ($tabButton.length > 0) {
				$tabButton.trigger('click.ddTabs');
			}
		};

		_onAttach = function() {
			_isDisabled = false;
			_aria.add();

			$tabsList.filter('.' + options.classes.isHidden).css({ opacity: 0 });
			$container.removeClass(options.classes.isDisabled);
		};

		_onDetach = function() {
			_isDisabled = true;
			_aria.remove();

			$tabsList.filter('.' + options.classes.isHidden).css({ opacity: '' });
			$container.addClass(options.classes.isDisabled);
		};

		_onDestroy = function() {
			_onDetach();
			$container.removeClass(options.classes.isDisabled);

			_aria.remove();
			$navigation.closest('.' + options.classes.navigation).remove();

			$tabsList.removeClass(options.classes.isHidden);

			$container.data('ddTabs-isInit', false);

			$container.off('.ddTabs');
			$(window).off('.ddTabs');
			$(document).off('.ddTabs');
		};

		_initTabs = function() {
			var initTabActions;

			initTabActions = function() {
				$navigation.find('li').each(function(i, el) {
					var $navListItem = $(el),
						$tabButton = $navListItem.find('button'),
						id,
						_onClick;

					if ($tabButton.length === 0) {
						// this occurs when a button isn't in the navigation list item because it's a tab group
						return;
					}

					id = $tabButton.data('ddTabs-controls');

					_onClick = function(event) {
						event.preventDefault();
						$container.trigger('tabChange.ddTabs', [id]);
					};

					$tabButton.on('click.ddTabs', _onClick);

					// setup initial show variables -
					if ((_activeIsSet && $(document.getElementById(id)).hasClass(options.classes.isActive)) || (!_activeIsSet && i === _firstActiveIndex)) {
						_activeTab = {
							id: id,
							$tab: $(document.getElementById(id)),
							$navListItem: $navListItem
						};

						_setNavListItemToActive($navListItem, options);
					}
				});

				// after the above so that we can set the active states by default
				_aria.add();

				if (options.updateHash) {
					var onHashChange = function(init) {
						init = (typeof (init) === 'boolean') ? init : false;

						var currentHash = window.location.hash;

						if (!currentHash || currentHash.indexOf(options.tabIdPrefix) < 0) {
							return;
						}

						currentHash = currentHash.replace(options.tabIdPrefix, '');

						$container.trigger('tabChange.ddTabs', [currentHash, init]);
					};

					$(window).on('hashchange.ddTabs', onHashChange);

					onHashChange(true);
				}
			};

			$tabsList.each(function(i, el) {
				var $tab = $(el),
					id = $tab.attr('id') || options.tabIdPrefix + tabsContainerIndex + '-tab-' + i,
					tabButton = options.tabButtonIdPrefix + id,
					title = $tab.attr(options.attrs.tabTitle) || $tab.find('> *').eq(0).text(),
					_activeTab = ((_activeIsSet && $tab.hasClass(options.classes.isActive)) || (!_activeIsSet && i === _firstActiveIndex));

				if ($tab.hasClass(options.classes.tabGroup)) {
					$navigation.append($('<li/>', {
						class: options.classes.tabGroup,
						html: title
					}));
				} else {
					$navigation.append($('<li/>', {
						attr: {
							role: 'presentation'
						},
						html: $('<button/>', {
							id: tabButton,
							html: $('<span/>', {
								class: options.classes.tabLabel,
								html: title
							})
						})
					}));

					$(document.getElementById(tabButton)).data('ddTabs-controls', $tab.attr('id'));
					$tab.data('ddTabs-labelledby', tabButton);

					if (_activeTab) {
						$tab.addClass(options.classes.isActive);
					} else {
						$tab.addClass(options.classes.isHidden).css({ opacity: 0 });
					}
				}

				if (i === _tabLength - 1) {
					initTabActions();
				}
			});
		};

		_initTabs();

		// unbind events to prevent multiple binds, then bind events
		$container.off('.ddTabs')
			.on('tabChange.ddTabs', _onTabChange)
			.on('destroy.ddTabs', _onDestroy);

		if (typeof (options.at) === 'string') {
			DD.bpAttach.at(options.at, _onAttach, _onDetach);
		}

		$(document).on('click.ddTabs', options.classes.tabsTrigger, _onTabTrigger);

		$container.data('ddTabs-isInit', true);
	};

	$.extend({
		ddTabs: {
			defaults: {
				updateHash: true,
				scroll: true,
				scrollOffset: -50,
				at: false,
				navFullWidth: false,
				tabIdPrefix: 'tab-',
				tabButtonIdPrefix: 'tabbutton-',
				attrs: {
					updateHash: 'data-tabs-update-hash',
					scroll: 'data-tabs-scroll',
					at: 'data-tabs-at',
					navFullWidth: 'data-tabs-nav-fullwidth',
					tabTitle: 'data-tab-title'
				},
				durations: {
					show: 200,
					hide: 200,
					scroll: 200
				},
				classes: {
					tabsTrigger: 'js-tabs-trigger',
					tabs: 'tabs',
					tab: 'tab',
					navigation: 'tabs-nav',
					hasItems: 'has-#-items',
					tabGroup: 'tab-group',
					tabLabel: 'tab-label',
					isActive: 'is-active',
					isDisabled: 'is-disabled',
					isHidden: 'is-hidden'
				},
				animations: {
					scrollPage: function($container, options, callback) {
						var pageTop = $(document).scrollTop(),
							pageBottom = pageTop + $(window).height(),
							offset;

						offset = $container.offset().top + options.scrollOffset;

						if (options.scroll === false || offset > pageTop && offset < pageBottom) {
							if (typeof (callback) === 'function') {
								callback();
							}

							// is currently in the page so don't scroll
							return;
						}

						// scroll the page
						$('html').velocity('stop').velocity('scroll', {
							offset: offset,
							duration: options.durations.scroll,
							complete: callback
						});
					}
				}
			}
		}
	}).fn.extend({
		ddTabs: function(options) {
			options = $.extend(true, {}, $.ddTabs.defaults, options);

			return $(this).each(function(i, el) {
				_init(el, options, i);
			});
		}
	});
})(jQuery, window, document);
